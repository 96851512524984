import { reactive } from "vue";
import googleSearchApi from "@/modules/keywords/api/google-search.api";

import { PositiveKeywordResponse } from "@/modules/keywords/models/keywords";
interface Keywords {
  list: PositiveKeywordResponse[];
  selectedKeywords: PositiveKeywordResponse[];
  searchText: string | null;
  selectedLanguage: string | null;
  page: 1;
  loading: boolean;
  isLoaded: boolean;
  keywordGroupId: number | null,
}
export const usePositiveKeyword = (profileId: string) => {
  const keywords: Keywords = reactive({
    list: [],
    selectedKeywords: [],
    selectedLanguage: "en",
    searchText: null,
    page: 1,
    loading: false,
    isLoaded: false,
    keywordGroupId: null,
  });

  const fetchKeywords = async (reset = false) => {
    if (reset) {
      resetKeywords();
    }
    if (!keywords.keywordGroupId) {
      return
    }
    const params = {
      keyword_group_id: keywords.keywordGroupId,
      profile_id: profileId,
      page: keywords.page,
      search: keywords.searchText,
      language: keywords.selectedLanguage,
    };

    keywords.loading = true;
    const { result, links } =
      await googleSearchApi.prototype.getPositiveKeywords(params);
    keywords.list = keywords.list.concat(result);
    keywords.loading = false;
    if (!links.next) {
      keywords.isLoaded = true;
      return;
    }
    keywords.page += 1;
  };
  const searchKeyword = (text: string) => {
    keywords.searchText = text;
    fetchKeywords(true);
  };

  const setSelectedKeyword = (items: PositiveKeywordResponse[]) => {
    keywords.selectedKeywords = [...items];
  };

  const resetKeywords = () => {
    keywords.page = 1;
    keywords.list = [];
    keywords.selectedKeywords = [];
  };

  const setSelectedLanguage = (language: string) => {
    keywords.selectedLanguage = language;
    fetchKeywords(true);
  };

  const removeKeyword = async () => {
    if (keywords.selectedKeywords.length > 0) {
      const ids = keywords.selectedKeywords.map(({ id }) => id);
      await googleSearchApi.prototype.deletePositiveKeywords(ids);
      fetchKeywords(true);
    }
  };

  const changeMatchType = async (keyword: PositiveKeywordResponse) => {
    const { id, ...rest } = keyword;
    const params = {
      profile_id: Number(profileId),
      ...rest,
    };
    keywords.loading = true;
    await googleSearchApi.prototype.updatePositiveKeywords(params, id);
    keywords.loading = false;
  };
  const moveToNegative = async () => {
    const ids = keywords.selectedKeywords.map((item) => item.id);
    if (ids.length < 1) return;

    const params = { ids };

    await googleSearchApi.prototype.moveToNegativeKeywords(params);
  };

  return {
    keywords,
    fetchKeywords,
    searchKeyword,
    setSelectedKeyword,
    setSelectedLanguage,
    removeKeyword,
    changeMatchType,
    resetKeywords,
    moveToNegative,
  };
};
