
import { defineComponent, computed, ref } from "vue";
import SelectInput from "@/core/components/forms/SelectInput.vue";

export default defineComponent({
  components: { SelectInput },
  props: {
    inputValue: { default: "", type: String },
    isAllLanguages: { default: false, type: Boolean },
    noPlaceholder: { default: false, type: Boolean },
  },
  setup(props, context) {
    let language = ref(props.inputValue);
    const languages = computed(() => {
      const availableLanguages = [
        { code: "all", name: "All Languages" },
        { code: "en", name: "English" },
        { code: "de", name: "German" },
        { code: "nl", name: "Dutch" },
        { code: "pt", name: "Portuguese" },
        { code: "es", name: "Spanish" },
        { code: "fr", name: "French" },
        { code: "it", name: "Italian" },
      ];
      return props.isAllLanguages
        ? availableLanguages
        : availableLanguages.filter((language) => {
            return language.code !== "all";
          });
    });

    function setData(value) {
      language.value = value;
      context.emit("update", value);
    }

    return {
      language,
      languages,
      setData,
    };
  },
});
