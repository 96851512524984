
import { defineComponent, ref, onMounted, watch } from "vue";
export default defineComponent({
  name: "EditableContentInput",
  props: {
    placeholder: { type: String, default: "" },
    value: String,
    noHTML: { type: Boolean, default: true },
  },
  emits: {
    returned: String,
    "update:value": String,
  },
  setup(props, { emit }) {
    const element = ref<HTMLElement | null>(null);

    function currentContent() {
      return props.noHTML ? element.value!.innerText : element.value!.innerHTML;
    }

    function updateContent(newcontent: string) {
      if (props.noHTML) {
        element.value!.innerText = newcontent;
        return;
      }

      element.value!.innerHTML = newcontent;
    }

    function update(event: any) {
      emit("update:value", currentContent());
    }

    function onKeyPress(event: any) {
      emit("returned", currentContent());
    }

    onMounted(() => {
      updateContent(props.value ?? "");
    });

    watch(
      () => props.value,
      (newval) => {
        if (newval !== currentContent()) {
          updateContent(newval ?? "");
        }
      }
    );
    return {
      update,
      onKeyPress,
      element,
    };
  },
});
