
import { computed, defineComponent, onMounted } from "vue";
import SearchKeyword from "@/modules/keywords/components/SearchKeyword.vue";
import { useRoute } from "vue-router";

import { PositiveKeywordResponse } from "@/modules/keywords/models/keywords";
import { usePositiveKeyword } from "@/modules/keywords/composable/usePositiveKeyword";
import { useKeywordGroup } from "@/modules/keywords/composable/useKeywordGroup";

export default defineComponent({
  components: { SearchKeyword },
  setup() {
    const route = useRoute();
    const profileId = route.params.profileId as string;
    const {
      keywords,
      fetchKeywords,
      searchKeyword,
      setSelectedKeyword,
      setSelectedLanguage,
      removeKeyword,
      changeMatchType,
      moveToNegative,
      resetKeywords,
    } = usePositiveKeyword(profileId);

    const { groups, removeGroupItem, fetchKeywordGroups, updateGroupName, updateKeywordGroupLanguage } =
      useKeywordGroup(profileId);

    const moveToNegativeKeywords = async () => {
      const selectedItemLength = keywords.selectedKeywords.length;
      await moveToNegative();
      fetchKeywords(true);
      updateKeywordGroupCount(selectedItemLength);
    };

    const selectedGroupItem = (item: PositiveKeywordResponse) => {
      keywords.keywordGroupId = keywords.keywordGroupId === item.id ? null : item.id;
      fetchKeywords(true);
    };

    const removeKeywordItems = async () => {
      const selectedItemLength = keywords.selectedKeywords.length;
      await removeKeyword();
      updateKeywordGroupCount(selectedItemLength);
    };

    const updateKeywordGroupCount = (removeItemLength: number) => {
      groups.list = groups.list.map((group) => ({
        ...group,
        keywordCount: group.id === keywords.keywordGroupId ? group.keywordCount - removeItemLength : group.keywordCount,
      }));
    };

    const onRemoveGroupItem = (item) => {
      removeGroupItem(item);
      keywords.keywordGroupId = null;
      resetKeywords();
    };

    const updateSelectedLanguage = async (language: string): Promise<void> => {
      await updateKeywordGroupLanguage(language);
      keywords.keywordGroupId = getFirstGroupItem.value;
      setSelectedLanguage(language);
    };

    const getFirstGroupItem = computed(() => {
      if (groups.list.length > 0) {
        return groups.list[0].id;
      }
      return null;
    });

    onMounted(async () => {
      await fetchKeywordGroups();
      if (getFirstGroupItem.value) {
        keywords.keywordGroupId = getFirstGroupItem.value;
        fetchKeywords();
      }
    });

    return {
      groups,
      keywords,
      searchKeyword,
      setSelectedKeyword,
      removeKeywordItems,
      changeMatchType,
      updateSelectedLanguage,
      onRemoveGroupItem,
      fetchKeywords,
      fetchKeywordGroups,
      moveToNegativeKeywords,
      updateGroupName,
      selectedGroupItem,
    };
  },
});
