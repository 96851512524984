
import ListTable from "@/core/components/ListTable.vue";
import BaseModal from "@/core/components/BaseModal.vue";
import Checkbox from "@/core/components/forms/Checkbox.vue";
import { defineComponent, ref, nextTick } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  components: { ListTable, BaseModal, Checkbox },
  props: {
    header: { type: Array, default: () => [] },
    items: { type: Array, default: () => [] },
    tableFooter: { type: Array, default: () => [] },
    modalTitle: { type: String },
    modalContent: { type: String },
    selectedGroupRow: { type: Number, default: null },
    isLoaded: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    selectedRowItems: { type: Array, default: () => [] },
    showCheckbox: { type: Boolean, default: false },
  },
  emits: {
    removeGroupItem: Object,
    loadMore: null,
    updateName: Object,
    selectedGroupItem: Object,
    setSelectedRowItems: Object,
  },
  setup(_, { emit }) {
    const openModalStatus = ref(false);
    const store = useStore();
    const inputs = ref<HTMLInputElement[]>([]);
    const readOnlyItem = ref<number | null>(null);

    let deletedItem = null;

    const removeGroup = (item) => {
      openModalStatus.value = true;
      deletedItem = item;
    };
    const editKeywordGroup = async (item) => {
      readOnlyItem.value = item.id;
      await nextTick();
      inputs.value[item.id].focus();
    };

    const confirm = () => {
      openModalStatus.value = false;
      emit("removeGroupItem", deletedItem);
    };

    const close = () => {
      openModalStatus.value = false;
    };

    const cancelChanges = () => {
      readOnlyItem.value = null;
    };

    const saveChanges = (item: Record<string, string | number>) => {
      const text = inputs.value[item.id].value;

      if (item.id !== readOnlyItem.value) return;

      if (!text.trim()) {
        store.dispatch("showToastNotification", {
          message: "Group name can not empty",
          isError: true,
          isSuccess: false,
          timeout: 5000,
        });
        return;
      }
      const updateItem = {
        ...item,
        name: text.trim(),
      };
      readOnlyItem.value = null;
      emit("updateName", updateItem);
    };
    return {
      inputs,
      readOnlyItem,
      openModalStatus,
      removeGroup,
      editKeywordGroup,
      confirm,
      close,
      saveChanges,
      cancelChanges,
    };
  },
});
