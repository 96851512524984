import { reactive } from "vue";
import { KeywordGroup } from "@/modules/keywords/models/keywords";
import googleSearchApi from "@/modules/keywords/api/google-search.api";
import { useStore } from "vuex";

interface KeywordGroups {
  list: KeywordGroup[];
  page: 1;
  loading: boolean;
  isLoaded: boolean;
  language: string;
}
export const useKeywordGroup = (profileId: string) => {
  const store = useStore();

  const groups: KeywordGroups = reactive({
    list: [],
    page: 1,
    loading: false,
    isLoaded: false,
    language: 'en'
  });
  const removeGroupItem = async (item: KeywordGroup) => {
    groups.loading = true;
    await googleSearchApi.prototype.deleteKeywordGroup(item.id);
    groups.list = groups.list.filter((group) => group.id !== item.id);
    groups.loading = false;
  };

  const updateKeywordGroupLanguage = async (language: string) => {
    groups.language = language;
    groups.isLoaded = false;
    await fetchKeywordGroups(true);
  };

  const fetchKeywordGroups = async (reset = false) => {
    if (reset) {
      resetKeywordsGroup();
    }
    const params = {
      profile_id: profileId,
      page: groups.page,
      language: groups.language
    };
    groups.loading = true;
    const { data, links } = await googleSearchApi.prototype.getKeywordGroups(
      params
    );
    groups.list = groups.list.concat(data);
    groups.loading = false;
    if (!links.next) {
      groups.isLoaded = true;
      return;
    }
    groups.page += 1;
  };

  const updateGroupName = async (item: KeywordGroup) => {
    const { id, language, name } = item;
    const { name: previous } =
      groups.list.find((group) => group.id === id) || {};
    if (name === previous) return;

    groups.loading = true;
    const params = {
      profile_id: profileId,
      name,
      language,
    };
    try {
      await googleSearchApi.prototype.updateKeywordGroup(id, params);
      groups.list = groups.list.map((group) => ({
        ...group,
        name: item.id === group.id ? item.name : group.name,
      }));
      setAlert("Keywords Group name updated successfully", false, true);
    } catch (err: any) {
      setAlert(err.response.data.message);
    } finally {
      groups.loading = false;
    }
  };
  const setAlert = (message, isError = true, isSuccess = false) => {
    store.dispatch("showToastNotification", {
      message,
      isError,
      isSuccess,
      timeout: 5000,
    });
  };

  const resetKeywordsGroup = () => {
    groups.page = 1;
    groups.list = [];
  };
  return {
    groups,
    removeGroupItem,
    fetchKeywordGroups,
    updateGroupName,
    updateKeywordGroupLanguage
  };
};
