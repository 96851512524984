
import {computed, defineComponent, PropType, ref} from 'vue';
import LoadingSpinner from '../LoadingSpinner.vue';
import MultiselectDropDownOptions from './MultiselectDropDownOptions.vue';
import {MultiselectOption} from '../../models/coreComponents.model';

export default defineComponent({
  components: {
    LoadingSpinner,
    MultiselectDropDownOptions,
  },
  props: {
    defaultTagColor: {default: '#122F62', type: String},
    disabled: {default: false, type: Boolean},
    fill: {default: '#FFFFFF', type: String},
    hasError: {default: false, type: Boolean},
    id: {default: 'multiselect-input', type: String},
    loading: {default: false, type: Boolean},
    noResultsMessage: {default: 'No results', type: String},
    openOnFocus: {default: true, type: Boolean},
    options: {default: () => [], type: Array as PropType<MultiselectOption[]>},
    placeholder: {default: 'Search selection', type: String},
    value: {default: () => [], type: Array as PropType<MultiselectOption[]>},
  },
  setup(props, context) {
    const main = ref<HTMLElement | null>(null);
    const id = ref<HTMLElement | null>(null);
    let opened = ref(false);
    let searchInput = ref('');

    const showNoResultsMessage = computed(() => {
      return opened.value && !!search.value.length;
    });

    const showDropDown = computed(() => {
      return props.openOnFocus ? opened.value : opened.value && !!search.value && !props.loading;
    })

    // Search
    const search = computed({
      get(): string {
        return searchInput.value;
      },
      set(input: string) {
        context.emit('search', input);
        searchInput.value = input;
      },
    });

    function emptySearch() {
      searchInput.value = '';
      context.emit('emptySearch')
    }

    function activateInput() {
      opened.value = true;
      id.value!.focus();
    }

    // Options Methods
    function select(option: MultiselectOption) {
      search.value = '';
      context.emit('select', option);
    };

    function remove(selected: MultiselectOption) {
      if (selected) {
        context.emit('remove', selected);
      }
    };

    const computedTagColor = computed((option: MultiselectOption) => {
      return option.tag ? option.tag : props.defaultTagColor;
    });

    // Toggle Logic
    function open() {
      if (!opened.value) {
        opened.value = true;
        const firstDocumentElement = document.firstElementChild! as unknown as HTMLElement;
        firstDocumentElement.click();
        document.addEventListener('click', clickedOutsideDropDown)
      }
    };

    function close() {
      opened.value = false;
      document.removeEventListener('click', clickedOutsideDropDown)
    }

    function clickedOutsideDropDown(event: any) {
      if (!main.value!.contains(event.target)) {
        close();
      }
    }
    
    return {
      activateInput,
      computedTagColor,
      emptySearch,
      main,
      open,
      opened,
      remove,
      search,
      searchInput,
      select,
      showDropDown,
      showNoResultsMessage,
    }
  },
})
