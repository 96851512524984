import Index from "@/modules/keywords/pages/Index.vue";
import GoogleSearchKeywords from "@/modules/keywords/pages/GoogleSearchKeywords.vue";
import SearchKeyword from "@/modules/keywords/pages/SearchKeyword.vue";
import AddKeyword from "@/modules/keywords/pages/AddKeyword.vue";
import UploadCSV from "@/modules/keywords/pages/UploadCSV.vue";
import NegativeKeyword from "@/modules/keywords/pages/NegativeKeyword.vue";
import FacebookSettings from "@/modules/keywords/pages/FacebookSettings.vue";
import TargetingPage from "@/modules/keywords/pages/TargetingPage.vue";
import AudienceUrlCriteria from "@/modules/keywords/pages/AudienceUrlCriteria.vue";

export default [
  {
    path: "/keywords/:profileId",
    name: "keywords",
    component: Index,
    redirect: { name: "google-search" },
    children: [
      {
        path: "google-search",
        name: "google-search",
        component: GoogleSearchKeywords,
        redirect: { name: "search-keywords" },
        children: [
          {
            path: "search-keywords",
            name: "search-keywords",
            meta: { parentName: "google-search" },
            component: SearchKeyword,
          },
          {
            path: "add-keywords",
            name: "add-keywords",
            meta: { parentName: "google-search" },
            component: AddKeyword,
          },
          {
            path: "negative-keywords",
            name: "negative-keywords",
            meta: { parentName: "google-search" },
            component: NegativeKeyword,
          },
          {
            path: "upload-csv",
            name: "upload-csv",
            meta: { parentName: "google-search" },
            component: UploadCSV,
          },
        ],
      },
      {
        path: "facebook-settings",
        name: "facebook-settings",
        component: FacebookSettings,
        redirect: { name: "targeting" },
        children: [
          {
            path: "targeting",
            name: "targeting",
            meta: { parentName: "facebook-settings" },
            component: TargetingPage,
          },
        ]
      },
    ],
  },
];
