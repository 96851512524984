
import {defineComponent, ref} from 'vue';

export default defineComponent({
  setup() {
    let inputString = ref('');

    function search() {
      console.log('SEARCH', inputString.value)
    }

    function clearSearch() {
      inputString.value = '';
    }
    
    return {
      clearSearch,
      inputString,
      search,
    }
  },
})
