import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, vShow as _vShow, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-469c27e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tool-tip-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("div", {
      onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showToolTip = true)),
      onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showToolTip = false))
    }, [
      _renderSlot(_ctx.$slots, "hoverable", {}, undefined, true)
    ], 32),
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass(["tool-tip", [{"tool-tip-animation" : _ctx.showToolTip, "error" : _ctx.hasError}, _ctx.cornerLocation]]),
      ref: "toolTip",
      style: _normalizeStyle({maxWidth: _ctx.maxWidth ? _ctx.maxWidth + "px" : "auto", width: _ctx.width ? _ctx.width + "px" : "auto", marginRight: _ctx.locationStyle})
    }, [
      _renderSlot(_ctx.$slots, "tool-tip", {}, undefined, true)
    ], 6), [
      [_vShow, !_ctx.hideToolTip]
    ])
  ]))
}