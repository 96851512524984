
import {defineComponent, onMounted, PropType, ref} from 'vue';
import {ToolTipCornerLocation} from '../models/coreComponents.model';

export default defineComponent({
  props: {
    cornerLocation: {default: 'bottom-right', type: String as PropType<ToolTipCornerLocation>},
    hasError: {default: false, type: Boolean},
    hideToolTip: {default: false, type: Boolean},
    horizontalOffset: {default: 0, type: Number},
    locationStyle: {default: '', type: String},
    maxWidth: {default: 0, type: Number},
    verticalOffset: {default: 0, type: Number},
    width: {default: 0, type: Number},
  },
  setup(props, context) {
    const toolTip = ref<HTMLElement | null>(null);
    const showToolTip = ref<boolean>(false);
    
    onMounted(() => {
      if (toolTip.value) {
        toolTip.value.style.bottom = props.verticalOffset + 'px';
        toolTip.value.style.right = props.horizontalOffset + 'px';
      };
    });

    return {
      showToolTip,
      toolTip,
    };
  },

})
