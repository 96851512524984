
import {computed, defineComponent, PropType} from 'vue';
import {useStore} from 'vuex';
import NamingAdvice from '../components/NamingAdvice.vue';
import SelectInput from '../../../core/components/forms/SelectInput.vue';
import TextInput from '../../../core/components/forms/TextInput.vue';
import {Profile} from '../models/profiles';

export default defineComponent({
  components: {
    NamingAdvice,
    SelectInput,
    TextInput
  },
  props: {
    profile: {default: null, type: null || Object as PropType<Profile>}
  },
  setup(props) {
    const store = useStore();
    const functionGroups = computed(() => {
      const names = store.state.profiles.functionGroups.map((item) => {
        return item.name;
      });
      return names;
    })
    
    const selectedProfile = computed(() => {
      return props.profile
    })

    const functionGroupValue = computed(() => {
      if (selectedProfile.value.functionGroup) {
        return selectedProfile.value.functionGroup.name;
      } else {
        return '';
      }
    })

    const lastEdited = computed(() => {
      const day = props.profile.updatedAt.day;
      const month = props.profile.updatedAt.month.name;
      const year = props.profile.updatedAt.year; 
      return `${day} ${month}, ${year}`;
    })

    function setName(input: string) {
      store.commit('updateName', input);
      store.commit('setEditingFlag', true);
    }

    function setFunctionGroup(input: string) {
      store.commit('updateFunctionGroup', input);
      store.commit('setEditingFlag', true);
    }
    
    return {
      functionGroups,
      functionGroupValue,
      lastEdited,
      selectedProfile,
      setFunctionGroup,
      setName,
    }
  },
})
