
import { defineComponent, onMounted } from "vue";
import KeywordForm from "@/modules/keywords/components/KeywordForm.vue";
import { useRoute } from "vue-router";
import { useNegativeKeyword } from "@/modules/keywords/composable/useNegativeKeyword";

export default defineComponent({
  components: { KeywordForm },
  setup() {
    const route = useRoute();
    const profileId = route.params.profileId as string;

    const {
      keywords,
      fetchNegativeKeywords,
      searchKeyword,
      setSelectedKeyword,
      removeKeyword,
      changeMatchType,
    } = useNegativeKeyword(profileId);

    onMounted(() => {
      fetchNegativeKeywords();
    });

    return {
      keywords,
      searchKeyword,
      fetchNegativeKeywords,
      setSelectedKeyword,
      removeKeyword,
      changeMatchType,
    };
  },
});
