import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import store from './core/store';
import './assets/variables.css';
import Auth from './auth/auth0';

// Global Components
import BaseModal from './core/components/BaseModal.vue';
import DefaultButton from './core/components/forms/DefaultButton.vue';
import DropDown from './core/components/DropDown.vue';
import ToastNotification from './core/components/ToastNotification.vue';
import auth0 from './auth/auth0';

async function init() {
  const AuthPlugin = await Auth.init({
    onRedirectCallback: async (appState) => {
      await auth0.fetchToken();

      router.push(
        appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname
      );
    },
  })
  createApp(App)
    .component('BaseModal', BaseModal)
    .component('DefaultButton', DefaultButton)
    .component('DropDown', DropDown)
    .component('ToastNotification', ToastNotification)
    .use(AuthPlugin)
    .use(store)
    .use(router)
    .mount('#app');
}

init();
