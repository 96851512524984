
import {computed, defineComponent, PropType, ref, toRefs, watch, onUpdated} from 'vue';
import {useRoute} from 'vue-router';

interface Clickable {
  click: () => void;
}

function isDropDownBelowViewport(bounding): boolean {
  return bounding.bottom > (window.innerHeight || document.documentElement.clientHeight);
}

export default defineComponent({
  props: {
    direction: {default: 'right', type: String as PropType<'left' | 'right'>},
    horizontalOffset: {default: 0, type: Number},
    shouldClose: {default: false, type: Boolean},
    verticalOffset: {default: 0, type: Number},
  },
  setup(props, context) {
    let opened = ref(false);
    const main = ref<HTMLElement | null>(null);
    const container = ref<HTMLElement | null>(null);
    const route = useRoute();
    const path = ref(route.path);
    const shouldClose = toRefs(props).shouldClose
    const verticalPositioning = ref('bottom');

    const isLeft = computed<boolean>(() => {
      return props.direction === 'left';
    });

    const isRight = computed<boolean>(() => {
      return props.direction === 'right';
    });

    const positioningStyle = computed(() => {
      if (props.direction === 'left' && verticalPositioning.value === 'top') {
        return {
          right: props.horizontalOffset + 'px',
        };
      } else {
        return {
          top: props.verticalOffset + 'px',
          right: props.horizontalOffset + 'px',
        };
      }
    });

    const bottomPositioningStyle = computed(() => {
      return verticalPositioning.value === "top" ? {"bottom" : "40px"} : "";
    })

    function open() {
      opened.value = true;
      const firstDocumentElement = document.firstElementChild as unknown as Clickable;
      firstDocumentElement.click();
      document.addEventListener('click', clickedOutsideDropDown!);
    };

    function close() {
      opened.value = false;
      document.removeEventListener('click', clickedOutsideDropDown!);
    };

    function clickedOutsideDropDown(event: any) {
      if (main.value && !main.value!.contains(event.target)) {
        close();
      }
    };

    function hasSlot(slotName = 'default') {
      return !!context.slots[slotName];
    }
    
    watch(path, () => {
      close();
    });

    watch(shouldClose, () => {
      close();
    });

    onUpdated(() => {
      if (opened.value && verticalPositioning.value === 'bottom') {
        verticalPositioning.value = isDropDownBelowViewport(container.value!.getBoundingClientRect()) ?
          'top' : 'bottom';
      }
    })

    return {
      bottomPositioningStyle,
      container,
      hasSlot,
      isLeft,
      isRight,
      main,
      open,
      opened,
      positioningStyle,
      verticalPositioning,
    };
  },
})
