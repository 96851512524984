
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import EditTargeting from "@/modules/profiles/components/EditTargeting.vue";
import FacebookInstagramTargeting from "@/modules/profiles/components/FacebookInstagramTargeting.vue";

export default defineComponent({
  components: {
    EditTargeting,
    FacebookInstagramTargeting,
  },
  setup() {
    const store = useStore();
    const selectedProfile = computed(() => {
      return store.state.profiles.selectedProfile;
    });

    store.commit("setEditingFlag", false);

    return {
      selectedProfile,
    };
  },
});
