
import {computed, defineComponent, ref} from 'vue';
import {useStore} from 'vuex';
import {sortIcon, stringSort} from '../../../core/hooks/sortingHooks';

export default defineComponent({
  setup() {
    const store = useStore();
    let sortToggle = ref(true);
    const activities = computed(() => store.state.profiles.activityLog);

    function sort(sortBy: string) {
      sortToggle.value = !sortToggle.value;
      if (sortBy === 'activity') {
        stringSort(activities, sortBy, sortToggle.value);
      }
      if (sortBy === 'author') {
        stringSort(activities, sortBy, sortToggle.value);
      }
    }
    
    return {
      activities,
      sort,
      sortIcon,
    }
  },
})
