
import {defineComponent} from 'vue';
import MultiSelectDropdown from '../../../core/components/forms/MultiselectDropDown.vue';

export default defineComponent({
  components: {
    MultiSelectDropdown,
  },
  setup() {
    
    return {

    }
  },
})
