import { Language } from "@/modules/profiles/models/profiles";

export const languages: Language[] = [
  { code: "all", name: "All Languages" },
  { code: "en", name: "English" },
  { code: "de", name: "German" },
  { code: "nl", name: "Dutch" },
  { code: "pt", name: "Portuguese" },
  { code: "es", name: "Spanish" },
  { code: "fr", name: "French" },
  { code: "it", name: "Italian" },
];
