import ActivityLog from "./pages/ActivityLog.vue";
import EditMainProfile from "./pages/EditMainProfile.vue";
import EditProfiles from "./pages/EditProfiles.vue";
import EditSubProfile from "./pages/EditSubProfile.vue";
import MainProfiles from "./pages/MainProfiles.vue";
import Profiles from "./pages/Profiles.vue";
import SubProfiles from "./pages/SubProfiles.vue";
import EditProfileChannels from "@/modules/profiles/pages/EditProfileChannels.vue";

export default [
  {
    path: "/profiles",
    name: "profiles",
    component: Profiles,
    children: [
      {
        path: "",
        component: MainProfiles,
      },
      {
        path: "subprofiles",
        component: SubProfiles,
      },
    ],
  },
  {
    path: "/edit-profile-channels",
    name: "edit-profile-channels",
    component: EditProfileChannels,
    children: [
      {
        path: "edit-profile",
        component: EditProfiles,
        children: [
          {
            path: ":profileId",
            name: "edit-profile",
            meta: { parentName: "edit-profile" },
            component: EditMainProfile,
          },
          {
            path: ":profileId/activity-log",
            name: "activity-log",
            component: ActivityLog,
          },
          {
            path: "subprofile/:subprofileId",
            component: EditSubProfile,
          },
        ],
      },
    ],
  },
];
