
import {defineComponent, PropType, ref} from 'vue';
import {MultiselectOption} from '../../models/coreComponents.model';

export default defineComponent({
  props: {
    defaultTagColor:{default: 'var(--color-primary)', type: String},
    fill:{default: '#FCFCFC', type: String},
    loading:{default: false, type: Boolean},
    maxHeight:{default: 215, type: Number},
    openOnClick:{default: false, type: Boolean},
    options :{default: () => [], type: Array as PropType<MultiselectOption[]>},
    titleHighlight:{default: '', type: String},
    value: {default: () => [], type: Array as PropType<MultiselectOption[]>},
  },
  setup(props, context) {
    const focusedOptionIndex = ref<number>(0);
    const inputsection = ref<HTMLDivElement | null>(null);
    const opened = ref<boolean>(false);
    const optionHeight = ref<number>(48);

    function highlight(value: string, highlight: string) {
      return value.replace(new RegExp(highlight, 'img'), (str) => {
        return str === '' ? '' : `<span class='bold'>${str}</span>`;
      });
    }

    function select(selectedOption: MultiselectOption) {
      const selected = selectedOption ? selectedOption : props.options[focusedOptionIndex.value];
      if (selected && !isSelected(selected)) {context.emit('select', selected)};
    }

    function isSelected(selectedOption: MultiselectOption) {
      return props.value.map((selected) => selected.value).includes(selectedOption.value);
    }
    
    return {
      focusedOptionIndex,
      highlight,
      inputsection,
      opened,
      optionHeight,
      select
    }
  },
})
