<template>
  <main class='loading-spinner'>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.1817 20.4244C19.4862 20.8599 18.8028 21.4888 18.5307 21.7489L18.4097 21.8638C17.5388 22.7407 17.4663 24.1378 17.4965 24.8937C17.5026 25.051 17.5691 25.1901 17.6779 25.2808C17.7868 25.4017 17.9259 25.4683 18.0771 25.4743C18.8331 25.5045 20.2301 25.4259 21.107 24.549L21.2219 24.428C21.4759 24.1559 22.1049 23.4725 22.5343 22.771C23.2963 22.7951 24.4575 22.9342 24.9958 22.3899C25.0623 22.2992 25.0744 22.1843 25.026 22.0815C24.9776 21.9787 23.9495 20.7933 23.9616 20.7752L24.5422 20.1462C24.8506 19.8317 24.0704 19.0395 23.9858 18.9488C23.8951 18.858 23.0968 18.0839 22.7762 18.4045L22.1533 18.9851C22.1472 18.9911 20.9558 17.963 20.853 17.9206C20.7502 17.8723 20.6353 17.8844 20.5446 17.9569C20.0124 18.5012 20.1515 19.6684 20.1817 20.4244Z" fill="#344088"/>
      <path opacity="0.5" d="M9.78591 23.4236C9.47747 23.4236 9.16904 23.3027 8.93317 23.0668C7.04022 21.1799 6 18.6701 6 15.997C6 13.3239 7.04022 10.814 8.92712 8.92712C10.814 7.04022 13.3239 6 15.997 6C18.6701 6 21.1799 7.04022 23.0668 8.92712C23.5386 9.39885 23.5386 10.1669 23.0668 10.6386C22.5951 11.1104 21.827 11.1104 21.3553 10.6386C19.922 9.20532 18.023 8.41911 15.997 8.41911C13.971 8.41911 12.072 9.20532 10.6386 10.6386C9.20532 12.072 8.41911 13.971 8.41911 15.997C8.41911 18.023 9.20532 19.922 10.6386 21.3553C11.1104 21.827 11.1104 22.5951 10.6386 23.0668C10.4028 23.3027 10.0943 23.4236 9.78591 23.4236Z" fill="#344088"/>
      <path d="M23.5747 15.997C23.5747 16.4264 23.5324 16.8437 23.4659 17.261C23.9679 17.4363 24.4214 17.8053 24.748 18.1258C25.1048 18.4826 25.3467 18.8092 25.4979 19.1055C25.8245 18.1137 25.9999 17.0674 25.9999 15.997C25.9999 13.3239 24.9597 10.814 23.0728 8.92712C21.1798 7.04022 18.67 6 15.9969 6C13.3238 6 10.8139 7.04022 8.92704 8.92712C8.45531 9.39885 8.45531 10.1669 8.92704 10.6386C9.39877 11.1104 10.1668 11.1104 10.6385 10.6386C12.0719 9.20532 13.9709 8.41911 15.9969 8.41911C18.0229 8.41911 19.9219 9.20532 21.3552 10.6386C22.7885 12.072 23.5747 13.971 23.5747 15.997Z" fill="#344088"/>
    </svg>
  </main>
</template>

<style>
.loading-spinner {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
  width: 100%;
}

.loading-spinner svg {
  animation: rotate 1.5s infinite linear;
  height: 100%;
  width: 100%;
}

@keyframes rotate {
  0% {transform: rotate(0deg)}
  100% {transform: rotate(360deg)}
}
</style>