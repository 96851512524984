import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6867ae84"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "main",
  class: "main"
}
const _hoisted_2 = { class: "drop-down-container" }
const _hoisted_3 = { class: "main-actions" }
const _hoisted_4 = {
  key: 0,
  class: "secondary-actions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", {
      class: "clickable",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.open && _ctx.open(...args)))
    }, [
      _renderSlot(_ctx.$slots, "clickable", {}, undefined, true)
    ]),
    _createElementVNode("section", _hoisted_2, [
      (_ctx.opened)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            ref: "container",
            class: _normalizeClass(["dropdown", {
          left: _ctx.isLeft,
          right: _ctx.isRight,
          "dropdown-arrow-bottom" : _ctx.verticalPositioning === "top",
          "dropdown-arrow-top" : _ctx.verticalPositioning === "bottom",
        }]),
            style: _normalizeStyle([_ctx.positioningStyle, _ctx.bottomPositioningStyle])
          }, [
            _createElementVNode("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "main-actions", {}, undefined, true)
            ]),
            (_ctx.hasSlot("secondary-actions"))
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _renderSlot(_ctx.$slots, "secondary-actions", {}, undefined, true)
                ]))
              : _createCommentVNode("", true)
          ], 6))
        : _createCommentVNode("", true)
    ])
  ], 512))
}