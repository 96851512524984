
import {defineComponent, ref} from 'vue';
import {useStore} from 'vuex';

export default defineComponent({
  setup() {
    const store = useStore();
    const windowIsOpen = ref(false);
    const activeChannel = ref('facebook');
    const retargetingList = store.state.profiles.retargetingList;

    function activateChannel(channelName: string) {
      activeChannel.value = channelName;
    }

    function changeWindowStatus() {
      windowIsOpen.value = !windowIsOpen.value;
    }
    
    return {
      activateChannel,
      activeChannel,
      changeWindowStatus,
      retargetingList,
      windowIsOpen,
    }
  },
})
