import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_add_keywords = _resolveComponent("add-keywords")!

  return (_openBlock(), _createBlock(_component_add_keywords, {
    "profile-id": _ctx.profileId,
    "selected-items": _ctx.selectedItems,
    onSubmit: _ctx.submit
  }, null, 8, ["profile-id", "selected-items", "onSubmit"]))
}