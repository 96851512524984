
import {computed, ComputedRef, defineComponent} from 'vue';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import EditProfileHeader from '../components/EditProfileHeader.vue';
import {Profile} from '../models/profiles';

export default defineComponent({
  components: {
    EditProfileHeader
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const selectedProfile: ComputedRef<Profile> = computed(() => {
      return store.state.profiles.selectedProfile;
    })

    const isDataLoaded: ComputedRef<boolean> = computed(() => {
      return store.state.profiles.selectedProfile && store.state.profiles.functionGroups;
    })
    
    const fieldsEdited = computed(() => {
      return store.state.profiles.fieldsEditedFlag;
    })

    async function saveChanges() {
      if (!selectedProfile.value.functionGroup) {
        const error = `Please select a function group for ${selectedProfile.value.name}`
        const params = {message: error, isError: true, timeout: 5000, isSuccess: false}
        store.dispatch('showToastNotification', params)
        return;
      }
      store.dispatch('updateProfile', selectedProfile.value);
      store.dispatch('showToastNotification', {
        message: `${selectedProfile.value.name} has been updated!`,
        isError: false,
        timeout: 3000,
        isSuccess: true
      })
      store.commit('setEditingFlag', false);
    }

    async function fetchDataOnPageRefresh() {
      if (!isDataLoaded.value) {
        await store.dispatch('fetchSingleProfile', route.params.profileId);
        await store.dispatch('fetchFunctionGroups');
      }
    }

    // Creation Lifecycle hooks
    fetchDataOnPageRefresh();
    store.dispatch('fetchLanguages');
    store.dispatch('fetchSearchTypes');
    
    return {
      isDataLoaded,
      fieldsEdited,
      saveChanges,
      selectedProfile,
    }
  },
})
