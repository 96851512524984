
import {defineComponent, PropType, ref} from 'vue';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';
import {Profile} from '../models/profiles';

export default defineComponent({
  props: {
    profile: {default: null, type: Object as PropType<Profile>}
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    let closeDropDown = ref(false);
    
    function duplicateProfile() {
      store.commit(
        'setModalParams',
        {isActive: true, component: 'duplicate', profile: props.profile}
      );
    }

    function deleteProfile() {
      store.commit(
        'setModalParams', 
        {isActive: true, component: 'delete', profile: props.profile}
      );
      closeDropDown.value = true;
    }

    async function editProfile() {
      await store.dispatch('fetchSingleProfile', props.profile.id);
      router.push({name: 'edit-profile', params: {profileId: props.profile.id}});
    }

    return {
      closeDropDown,
      deleteProfile,
      duplicateProfile,
      editProfile,
    }
  },
})
