
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    disabled: { default: false, required: false, type: Boolean },
    color: { default: "", type: String },
  },
  setup(props, context) {
    function click() {
      if (!props.disabled) {
        context.emit("activate");
      }
    }

    return {
      click,
    };
  },
});
