
import {defineComponent} from 'vue';
import {useStore} from 'vuex';

export default defineComponent({
  setup() {
    const store = useStore();

    function deleteSynonym() {
      store.dispatch('removeSynonymFromProfile', {
        profileId: store.state.profiles.selectedProfile.id,
        synonymId: store.state.profiles.activeModal.synonym.id,
      });
      close();
    }

    function close() {
      store.commit('closeModal')
    }
    
    return {
      close,
      deleteSynonym,
    }
  },
})
