
import { computed, defineComponent } from "vue";
import InputLabel from "./InputLabel.vue";
import ToolTip from "../ToolTip.vue";

export default defineComponent({
  components: {
    InputLabel,
    ToolTip,
  },
  props: {
    capitalize: { default: false, type: Boolean },
    customDisabledMessage: {},
    disabled: { default: false, type: Boolean },
    emptyIfDisabled: { default: false, type: Boolean },
    error: { default: false, type: Boolean },
    fill: { default: "#FCFCFC", type: String },
    id: { default: 0, type: Number },
    multiple: { default: false, type: Boolean },
    nullSelectable: { default: false, type: Boolean },
    options: { default: () => [], type: Array },
    placeholder: { default: "Select an option", type: String },
    selectOnMounted: { default: false, type: Boolean },
    value: { default: "", type: String },
    itemLabel: { default: "", type: String },
    itemKey: { default: "", type: String },
    noPlaceholder: { default: false, type: Boolean },
  },
  setup(props, context) {
    const activeItem = computed({
      get(): string {
        return isDisabledAndShouldBeEmpty.value ? "-" : props.value;
      },
      set(event): void {
        context.emit("input", event);
      },
    });

    const disabledMessage = computed(() => {
      return props.customDisabledMessage
        ? props.customDisabledMessage
        : "Cannot alter this option";
    });

    const disabledStyle = computed(() => {
      if (props.disabled) {
        return { opacity: 0.5 };
      }
      return {};
    });

    const isDisabledAndShouldBeEmpty = computed(() => {
      return props.emptyIfDisabled && props.disabled;
    });

    function format(option: string) {
      if (props.capitalize) {
        return option.charAt(0).toUpperCase() + option.substring(1);
      }
      if (props.itemLabel) {
        return option[props.itemLabel];
      }
      return option;
    }

    function select(event) {
      context.emit("onSelect", event.target.value);
    }

    function optionKey(option: string | Record<string, string>) {
      if (typeof option === "object") {
        return option[props.itemKey];
      }
      return option;
    }

    return {
      activeItem,
      disabledMessage,
      disabledStyle,
      format,
      isDisabledAndShouldBeEmpty,
      select,
      optionKey,
    };
  },
});
