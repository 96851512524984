
import { defineComponent, PropType, ref } from "vue";
import debounce from "lodash.debounce";

//Components
import TextInput from "@/core/components/forms/TextInput.vue";
import DefaultButton from "@/core/components/forms/DefaultButton.vue";
import LanguageSelector from "@/modules/profiles/components/LanguageSelector.vue";

// Models - Service
import { languages } from "@/core/store/languageList";
import { KeywordResponse } from "@/modules/keywords/models/keywords";
import KeywordTable from "@/modules/keywords/components/KeywordTable.vue";
export default defineComponent({
  components: {
    TextInput,
    KeywordTable,
    DefaultButton,
    LanguageSelector,
  },
  props: {
    searchBoxPlaceholder: { type: String, default: "Search Keywords" },
    keywords: {
      type: Array as PropType<KeywordResponse[]>,
      default: () => [],
    },
    selectedKeywords: {
      type: Array as PropType<KeywordResponse[]>,
      default: () => [],
    },
    selectedLanguage: { type: String, default: "" },
    hideLanguage: { type: Boolean, default: false },
    isLoaded: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },
  emits: {
    search: null,
    setSelectedLanguage: String,
    setSelectedKeyword: null,
    changeMatchType: Object,
    loadMore: null,
    removeKeyword: null,
  },
  setup(props, { emit }) {
    const searchText = ref<string>("");
    const setSearchText = debounce((text: string) => {
      emit("search", text.trim());
    }, 500);
    const setSelectedKeyword = (keyword: KeywordResponse) => {
      const hasItem = props.selectedKeywords.find(
        (item) => item.id === keyword.id
      );
      let keywords = [...props.selectedKeywords];
      if (hasItem) {
        keywords = keywords.filter((item) => item.id !== keyword.id);
        emit("setSelectedKeyword", keywords);
        return;
      }
      keywords.push(keyword);
      emit("setSelectedKeyword", keywords);
    };
    return {
      setSearchText,
      setSelectedKeyword,
      searchText,
      languages,
    };
  },
});
