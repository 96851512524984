
import {defineComponent, inject} from 'vue';
import DropDown from './DropDown.vue';

export default defineComponent({
  components: {
    DropDown,
  },
  setup() {

    const Auth0: any = inject('Auth');

    // console.log('AUTH OBJECT', Auth0)
    // console.log('LOCAL STORAGE', localStorage.setItem)

    function signOut() {
      Auth0.logout();
    }
    
    return {
      signOut,
    }
  },
})
