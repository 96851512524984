import Vuex from 'vuex';
import profiles from '../../modules/profiles/store';
import toastNotification from './toastNotification.store';

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    profiles,
    toastNotification,
  },
});

export default store;