
import {computed, defineComponent} from 'vue';
import {useStore} from 'vuex';

export default defineComponent({
  setup() {
    const store = useStore();
    const notificationState = computed(() => {
      return store.state.toastNotification.toastParams;
    })

    function closeNotification() {
      store.commit('closeToastNotification');
    };
  return {
    closeNotification,
    notificationState,
  };
  },
})
