<template>
  <div class='body'>
    <section class='header'>
      <h6>Naming Advice</h6>
      <img src='@/core/assets/icons/icon-lightbulb.svg' atl='lightblulb'/>
    </section>
    <div class='info'>
      When choosing a name for your profile please make sure to reference the
      <a>Wonderkind Thesaurus</a> in order to select a name that
      appropriately matches a "Preferred Term" from the thesaurus.
    </div>
  </div>
</template>

<style scoped>
.body {
  border-radius: var(--border-radius);
  margin-top: 4px;
  width: 100%;
  background-color: #FFFFFF;
  padding: 8px 10px 8px 10px;
  border: 1px solid rgba(0, 0, 0, 0.06);
}

.header {
  display: flex;
}

h6 {
  font-size: 20px;
  font-weight: 600;
}

.info {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
}

a {
  color: var(--color-secondary);
  cursor: pointer;
  opacity: 1;
  text-decoration: underline;
}
</style>