
import { defineComponent, PropType, ref } from "vue";
import { KeywordResponse } from "@/modules/keywords/models/keywords";
import Checkbox from "@/core/components/forms/Checkbox.vue";
import ListTable from "@/core/components/ListTable.vue";

export default defineComponent({
  components: { Checkbox, ListTable },
  props: {
    keywords: {
      type: Array as PropType<KeywordResponse[]>,
      default: () => [],
    },
    isLoaded: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    selectedKeywords: {
      type: Array as PropType<KeywordResponse[]>,
      default: () => [],
    },
  },
  emits: {
    setSelectedKeyword: null,
    changeMatchType: Object,
    loadMore: null,
  },
  setup(props, { emit }) {
    const localLoading = ref(false);
    const groupHeader = [{ name: "Keywords" }, { name: "Match Type" }];

    const isActive = (id: string | number) => {
      return props.selectedKeywords.find(
        (item: KeywordResponse) => item.id === id
      );
    };

    const changeMatchTypes = (item) => {
      emit("changeMatchType", JSON.parse(JSON.stringify(item)));
    };
    return {
      groupHeader,
      localLoading,
      isActive,
      changeMatchTypes,
    };
  },
});
