
import {computed, defineComponent, ref} from 'vue';
import {useStore} from 'vuex';
import AddTargeting from './AddTargeting.vue';
import EstimatedReach from '../components/EstimatedReach.vue';
import TargetingSelector from './TargetingSelector.vue';
import {SingleTarget} from '../models/profiles';

export default defineComponent({
  components: {
    AddTargeting,
    EstimatedReach,
    TargetingSelector,
  },
  setup() {
    const store = useStore();
    const excludeIsOpen = ref<boolean>(false);
    const narrowIsOpen = ref<boolean>(false);
    
    const selectedTargetingType = computed(() => {
      return store.state.profiles.selectedTargetingType;
    })
    const profileIncludesTargeting = computed(() => {
      const includesDetailed = store.state.profiles.selectedProfile.targeting.detailed.includes.length > 0;
      const excludesDetailed = store.state.profiles.selectedProfile.targeting.detailed.excludes.length > 0;
      const includesRegulated = store.state.profiles.selectedProfile.targeting.regulated.includes.length > 0;
      const excludesRegulated = store.state.profiles.selectedProfile.targeting.regulated.excludes.length > 0;
      return includesDetailed || includesRegulated || excludesDetailed || excludesRegulated;
    })

    function showHideExclude() {
      excludeIsOpen.value = !excludeIsOpen.value;
    }

    function showHideNarrow() {
      narrowIsOpen.value = !narrowIsOpen.value;
    }

    function addTargeting(payload) {
      if(checkDetailedTargeting(payload.target) || checkRegulatedTargeting(payload.target)) {
        showDuplciateTargetingNotification();
        return;
      }
      switch (selectedTargetingType.value) {
        case 'detailed' : store.commit('addDetailedTargeting', {target: payload.target, type: payload.focus});
          break;
        case 'regulated' : store.commit('addRegulatedTargeting', {target: payload.target, type: payload.focus})
          break;
      }
      store.commit('setEditingFlag', true);
    }

    function checkDetailedTargeting(target: SingleTarget) {
      const includesHasDuplicate = store.state.profiles.selectedProfile!.targeting!.detailed.includes.filter((item) => {
        return item.id === target.id;
      })
      const excludesHasDuplicate = store.state.profiles.selectedProfile!.targeting!.detailed.excludes.filter((item) => {
        return item.id === target.id;
      })
      return (!!includesHasDuplicate.length || !!excludesHasDuplicate.length);
    }

    function checkRegulatedTargeting(target: SingleTarget) {
      const includesHasDuplicate = store.state.profiles.selectedProfile!.targeting!.regulated.includes.filter((item) => {
        return item.id === target.id;
      })
      const excludesHasDuplicate = store.state.profiles.selectedProfile!.targeting!.regulated.excludes.filter((item) => {
        return item.id === target.id;
      })
      return (!!includesHasDuplicate.length || !!excludesHasDuplicate.length);
    }

    function showDuplciateTargetingNotification() {
      const profileName = store.state.profiles.selectedProfile.name;
      store.dispatch('showToastNotification', {
        message: `This targeting already exists for ${profileName}!`,
        isError: true,
        isSuccess: false,
      });
    }

    return {
      addTargeting,
      excludeIsOpen,
      narrowIsOpen,
      profileIncludesTargeting,
      selectedTargetingType,
      showHideExclude,
      showHideNarrow,
    }
  },
})
