
import {computed, defineComponent} from 'vue';
import FormToolTip from '../FormToolTip.vue';
import IconAlert from '../../assets/icons/icon-alert.vue';

export default defineComponent({
  components: {
    FormToolTip,
    IconAlert,
  },
  props: {
    disabled: {default: false, type: Boolean},
    hasError: {default: false, type: Boolean},
    hasWarning: {default: false, type: Boolean},
    // id: {default: 'label', required: true, type: String},
    warningMessage: {default: 'Something went wrong', type: String},
  },
  setup(props) {
    const hasWarningOrError = computed(() => {
      return props.hasError || props.hasWarning;
    });

    return {
      hasWarningOrError,
    }
  },
})
