import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ddffd61"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tooltip-location" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_alert = _resolveComponent("icon-alert")!
  const _component_form_tool_tip = _resolveComponent("form-tool-tip")!

  return (_openBlock(), _createElementBlock("main", null, [
    _createVNode(_component_form_tool_tip, {
      "has-error": _ctx.hasError,
      "horizontal-offset": -9,
      "vertical-offset": 30,
      "hide-tool-tip": !_ctx.hasWarningOrError
    }, {
      hoverable: _withCtx(() => [
        _createElementVNode("label", {
          class: _normalizeClass({disabled: _ctx.disabled}),
          style: _normalizeStyle({color: _ctx.hasError ? "var(--color-warning)" : "var(--color-primary)"})
        }, [
          _renderSlot(_ctx.$slots, "label", {}, undefined, true),
          (_ctx.hasWarningOrError)
            ? (_openBlock(), _createBlock(_component_icon_alert, {
                key: 0,
                class: "alert",
                color: _ctx.hasError ? "var(--color-warning)" : "var(--color-primary)"
              }, null, 8, ["color"]))
            : _createCommentVNode("", true)
        ], 6)
      ]),
      "tool-tip": _withCtx(() => [
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.warningMessage), 1)
      ]),
      _: 3
    }, 8, ["has-error", "hide-tool-tip"])
  ]))
}