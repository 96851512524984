
import { defineComponent, ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import Sidebar from "@/modules/keywords/components/Sidebar.vue";

export default defineComponent({
  components: { Sidebar },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const navItems: Array<Record<string, string>> = [
      {
        content: "Targeting",
        name: "targeting",
      },
    ];
    let activeItem = ref("");

    function navigateTo(section) {
      activeItem.value = section.name;
      router.push({ name: activeItem.value });
    }

    onMounted(() => {
      activeItem.value = route.name as string;
    });

    return {
      navItems,
      activeItem,
      navigateTo,
    };
  },
});
