import { vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d0e86eb4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-field" }
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = {
  key: 0,
  class: "options"
}
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_spinner = _resolveComponent("loading-spinner")!

  return (_openBlock(), _createElementBlock("main", null, [
    _createElementVNode("section", _hoisted_1, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
        placeholder: _ctx.searchPlaceholder,
        onKeydown: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.emptySearch && _ctx.emptySearch(...args)), ["esc"]))
      }, null, 40, _hoisted_2), [
        [_vModelText, _ctx.search]
      ]),
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_loading_spinner, {
            key: 0,
            class: "loading"
          }))
        : _createCommentVNode("", true)
    ]),
    (_ctx.targetingOptions !== null)
      ? (_openBlock(), _createElementBlock("section", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.targetingOptions, (option, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "single-option",
              style: _normalizeStyle({
          backgroundColor: index % 2 === 0 ? 'rgba(0, 0, 0, 0.06)' : null,
        })
            }, [
              _createElementVNode("p", {
                onClick: ($event: any) => (_ctx.select(option)),
                style: _normalizeStyle({
            color:
              option.id === _ctx.selectedTarget.id ? 'var(--color-secondary)' : null,
          })
              }, _toDisplayString(option.name), 13, _hoisted_4)
            ], 4))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}