import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_keyword_form = _resolveComponent("keyword-form")!

  return (_openBlock(), _createBlock(_component_keyword_form, {
    "search-box-placeholder": "Search Negative Keywords",
    keywords: _ctx.keywords.list,
    "selected-keywords": _ctx.keywords.selectedKeywords,
    "is-loaded": _ctx.keywords.isLoaded,
    loading: _ctx.keywords.loading,
    "hide-language": "",
    onSearch: _ctx.searchKeyword,
    onRemoveKeyword: _ctx.removeKeyword,
    onChangeMatchType: _ctx.changeMatchType,
    onSetSelectedKeyword: _ctx.setSelectedKeyword,
    onLoadMore: _ctx.fetchNegativeKeywords
  }, null, 8, ["keywords", "selected-keywords", "is-loaded", "loading", "onSearch", "onRemoveKeyword", "onChangeMatchType", "onSetSelectedKeyword", "onLoadMore"]))
}