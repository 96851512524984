
import { computed, ref, defineComponent, PropType } from "vue";
import LoadingSpinner from "../../../core/components/LoadingSpinner.vue";
import { SingleTarget } from "../models/profiles";

export default defineComponent({
  components: {
    LoadingSpinner,
  },
  props: {
    loading: { default: false, required: false, type: Boolean },
    searchPlaceholder: { required: true, type: String },
    targetingOptions: {
      default: null,
      required: false,
      type: null || (Array as PropType<SingleTarget[]>),
    },
  },
  setup(props, context) {
    let searchInput = ref("");
    const selectedTarget = ref<SingleTarget>({
      id: "",
      name: "",
      type: "",
      path: [""],
      audienceSize: 0,
    });
    const search = computed({
      get() {
        return searchInput.value;
      },
      set(input) {
        context.emit("search", input);
      },
    });

    function emptySearch() {
      searchInput.value = "";
      context.emit("emptySearch");
    }

    function select(target: SingleTarget) {
      selectedTarget.value = target;
      context.emit("select", target);
    }

    return {
      emptySearch,
      search,
      select,
      selectedTarget,
    };
  },
});
