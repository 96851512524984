
import {computed, defineComponent, ref} from 'vue';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';
import FunctionGroupSelector from '../components/FunctionGroupSelector.vue';
import ProfileDropDown from '../components/ProfileDropDown.vue';
import Search from '../../../core/components/Search.vue';
import {sortIcon, stringSort, numberSort} from '../../../core/hooks/sortingHooks';

export default defineComponent({
  components: {
    FunctionGroupSelector,
    ProfileDropDown,
    Search,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    let sortToggle = ref(true);
    const profiles = computed(() => store.getters['filteredProfiles']);

    // Lifecycle hooks
    store.dispatch('fetchAllProfiles');
    store.dispatch('fetchFunctionGroups');

    function sort(sortBy: string) {
      sortToggle.value = !sortToggle.value;
      sortBy === 'name' ? stringSort(profiles.value, sortBy, sortToggle.value) :
      numberSort(profiles.value, sortBy, sortToggle.value);
    }

    async function editProfileChannels(id: number) {
      await store.dispatch('fetchSingleProfile', id);
      router.push({name: 'google-search', params: {profileId: id}});
    }

    return {
      editProfileChannels,
      profiles,
      sort,
      sortIcon,
    }
  },
})
