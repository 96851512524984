import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-67b8b6f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "infinite-table-wrapper" }
const _hoisted_2 = {
  class: "infinite-table",
  ref: "table"
}
const _hoisted_3 = { class: "table" }
const _hoisted_4 = { class: "header" }
const _hoisted_5 = { class: "body" }
const _hoisted_6 = {
  key: 0,
  class: "no-result"
}
const _hoisted_7 = ["colspan"]
const _hoisted_8 = {
  key: 0,
  class: "footer"
}
const _hoisted_9 = {
  key: 1,
  class: "footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_spinner = _resolveComponent("loading-spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("table", _hoisted_3, [
        _createElementVNode("thead", _hoisted_4, [
          _createElementVNode("tr", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.header, (item, index) => {
              return (_openBlock(), _createElementBlock("th", {
                key: index,
                style: _normalizeStyle({ width: item.width })
              }, _toDisplayString(item.name), 5))
            }), 128))
          ])
        ]),
        _createElementVNode("tbody", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
            return (_openBlock(), _createElementBlock("tr", {
              class: "item",
              key: item.id
            }, [
              _renderSlot(_ctx.$slots, "body-row", { item: item }, undefined, true)
            ]))
          }), 128)),
          (_ctx.hasResult)
            ? (_openBlock(), _createElementBlock("tr", _hoisted_6, [
                _createElementVNode("td", {
                  colspan: _ctx.header.length
                }, "No data found", 8, _hoisted_7)
              ]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.footer.length)
          ? (_openBlock(), _createElementBlock("tfoot", _hoisted_8, [
              _createElementVNode("tr", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.footer, (item, i) => {
                  return (_openBlock(), _createElementBlock("td", { key: i }, _toDisplayString(item), 1))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.$slots.tfoot)
          ? (_openBlock(), _createElementBlock("tfoot", _hoisted_9, [
              _createElementVNode("tr", null, [
                _renderSlot(_ctx.$slots, "tfoot", {}, undefined, true)
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ], 512),
    (_ctx.localLoading)
      ? (_openBlock(), _createBlock(_component_loading_spinner, {
          key: 0,
          class: "loader"
        }))
      : _createCommentVNode("", true)
  ]))
}