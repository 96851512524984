
import {computed, ComputedRef, defineComponent, ref} from 'vue';
import {useStore} from 'vuex';
import {Language, Synonym} from '../models/profiles';

export default defineComponent({
  setup() {
    const store = useStore();
    const languages = store.state.profiles.languages;

    const selectedSynonym = computed<Synonym>(() => {
      return store.state.profiles.activeModal.synonym;
    })

    const disableConfirm = computed<boolean>(() => {
      const noSynonym = synonymLanguages.value.length === 0;
      // TODO - needs to fix logic to disable button when same two languages as inital are selected
      // const sameLanguages = true;
      if (noSynonym) {
        return true;
      } else {
        return false;
      }
    })

    let synonymLanguages = ref<Language[]>([]);

    function filterLanguages(language: Language) {
      return synonymLanguages.value.filter((item) => {
        return item.code === language.code;
      }).length;
    }

    function selectAll() {
      synonymLanguages.value = languages;
    }
    
    function deselectAll() {
      synonymLanguages.value = [];
    }

    function toggleLanguage(language: Language) {
      if (!filterLanguages(language)) {
        addLanguage(language);
      } else if (filterLanguages(language)) {
        removeLanguage(language);
      }
      return;
    }

    function addLanguage(language: Language) {
      synonymLanguages.value.push(language);
    }

    function removeLanguage(language: Language) {
      const newLanguages = synonymLanguages.value.filter((item) => {
        return item.code !== language.code;
      })
      synonymLanguages.value = newLanguages;
    }

    function close() {
      store.commit('closeModal');
    }

    function confirmLanguages() {
      store.commit('updateLanguages', synonymLanguages.value);
      store.commit('setEditingFlag', true);
      close();
    }

    function initialiseLanguages() {
      synonymLanguages.value = selectedSynonym.value.languages;
    }

    initialiseLanguages();

    return {
      close,
      confirmLanguages,
      deselectAll,
      disableConfirm,
      filterLanguages,
      languages,
      selectAll,
      selectedSynonym,
      synonymLanguages,
      toggleLanguage,
    }
  },
})
