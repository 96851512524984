
import { defineComponent } from "vue";
import ProfileNavBar from "../components/ProfileNavBar.vue";

export default defineComponent({
  components: {
    ProfileNavBar,
  },
  setup() {
    return {};
  },
});
