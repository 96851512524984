
import {computed, defineComponent, ref} from 'vue';
import {useStore} from 'vuex';
import TextInput from '../../../core/components/forms/TextInput.vue';
import {Language, Synonym} from '../models/profiles';

export default defineComponent({
  components: {
    TextInput,
  },
  setup() {
    const store = useStore()
    const languages: Language[] = store.state.profiles.languages;
    const newSynonym = ref<Synonym>({
      id: Math.random(),
      name: '',
      languages: [],
    })
    const synonymIncomplete = computed(() => {
      return newSynonym.value.name === '' || newSynonym.value.languages.length === 0 ? true : false;
    })

    function createSynonym() {
      store.dispatch('createSynonym', {
        name: newSynonym.value.name, 
        languages: newSynonym.value.languages, 
        profileId: store.state.profiles.selectedProfile.id,
      });
      close();
    }
    
    function close() {
      store.commit('closeModal');
    }

    function selectAll() {
      newSynonym.value.languages = languages;
    }

    function deselectAll() {
      newSynonym.value.languages = [];
    }

    function updateName(event) {
      newSynonym.value.name = event;
    }

    function toggleLanguage(language: Language) {
      if (!filterLanguages(language)) {
        addLanguage(language);
      } else if (filterLanguages(language)) {
        removeLanguage(language);
      }
      return;
    }

    function filterLanguages(language: Language) {
      return newSynonym.value.languages.filter((item) => {
        return item.code === language.code;
      }).length
    }

    function addLanguage(language: Language) {
      newSynonym.value.languages.push(language);
    }

    function removeLanguage(language: Language) {
      const newLanguages = newSynonym.value.languages.filter((item) => {
        return item.code !== language.code;
      })
      newSynonym.value.languages = newLanguages;
    }

    return {
      toggleLanguage,
      close,
      createSynonym,
      deselectAll,
      filterLanguages,
      languages,
      newSynonym,
      selectAll,
      synonymIncomplete,
      updateName,
    } 
  },
})
