
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import AddKeywords, {
  IselectedData,
} from "@/modules/keywords/components/AddKeywords.vue";
import { EAdGroup, TAddKeywords } from "@/modules/keywords/models/keywords";
import googleSearchApi from "@/modules/keywords/api/google-search.api";
import { useStore } from "vuex";
import sanitizeHtml from "sanitize-html";

const defaultData: IselectedData = {
  keywordCategory: EAdGroup.POSITIVE,
  language: "en",
  group: {},
  keywords: "",
};

export default defineComponent({
  components: { AddKeywords },
  setup() {
    let selectedItems = ref(defaultData);
    const route = useRoute();
    const store = useStore();
    const profileId = route.params.profileId as string;

    const stringToArray = (text: string) => {
      return text.split(/\r?\n/);
    };
    const setAlert = (message, isError = true, isSuccess = false) => {
      store.dispatch("showToastNotification", {
        message,
        isError,
        isSuccess,
        timeout: 5000,
      });
    };
    const getKeywordList = (keyword: string) => {
      const clearHTML = sanitizeHtml(keyword, {
        allowedTags: [],
        allowedAttributes: {},
      });
      return stringToArray(clearHTML).filter((keyword) => keyword.length > 0);
    };
    const submit = async (data: IselectedData) => {
      const names = getKeywordList(data.keywords);

      if (names.length < 1) {
        setAlert("Keyword field does not empty");
        return;
      }

      let params: TAddKeywords = {
        profile_id: profileId,
        names,
      };

      let keywordType = EAdGroup.NEGATIVE;

      if (data.keywordCategory === EAdGroup.POSITIVE) {
        if (!data.language) {
          setAlert("Please select language");
          return;
        }
        if (!data.group.id) {
          setAlert("Please select ad group");
          return;
        }
        params = {
          ...params,
          language: data.language,
          keyword_group_id: data.group.id,
        };
        keywordType = EAdGroup.POSITIVE;
      }
      try {
        await googleSearchApi.prototype.createKeyword(params, keywordType);
        selectedItems.value = JSON.parse(JSON.stringify(defaultData));
        setAlert("Keywords added successfully", false, true);
      } catch (err: any) {
        setAlert(err.response.data.message);
        return;
      }
    };

    return {
      profileId,
      selectedItems,
      submit,
    };
  },
});
