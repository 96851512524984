
import {defineComponent, ref, computed, PropType} from 'vue';
import {useStore} from 'vuex';
import TargetingSelector from './TargetingSelector.vue'
import {SingleTarget, TargetingFocus} from '../models/profiles';

export default defineComponent({
  props: {
    id: {default: '', required: true, type: String},
    targetingFocus: {default: 'includes', required: false, type: String as PropType<TargetingFocus>}
  },
  components: {
    TargetingSelector,
  },
  setup(props, context) {
    const store = useStore();
    const child = ref<any>(null);
    const searchIsLoading = ref<boolean>(false);
    const searchQuery = ref<string>('');
    const options = ref<null | SingleTarget[]>(null);
    const selectedTarget = ref<null | SingleTarget>(null);
    
    const noTargetSelected = computed(() => {
      return selectedTarget.value === null;
    })
    const disableSearch = computed(() => {
      return searchQuery.value === '';
    })

    const targetingPlaceholder = computed(() => {
      return props.targetingFocus === 'includes' ? 'Search narrow audience options...'
        : 'Search exclude audience options...';
    })

    async function searchForTargeting() {
      searchIsLoading.value = true;
      const type = store.state.profiles.selectedTargetingType;
      const search = await store.dispatch('searchTargetingData', {value: searchQuery.value, type});
      options.value = search;
      searchIsLoading.value = false;
    }

    function updateSearchQuery(input: string) {
      searchQuery.value = input;
    }

    function emptySearch() {
      searchQuery.value = '';
      options.value = [];
    }

    function select(value) {
      selectedTarget.value = value;
    }

    function addTargeting() {
      context.emit('add', {target: selectedTarget.value, focus: props.targetingFocus});
      selectedTarget.value = null;
      child.value.emptySearch();
    }

    return {
      addTargeting,
      child,
      disableSearch,
      emptySearch,
      noTargetSelected,
      options,
      searchForTargeting,
      searchIsLoading,
      select,
      updateSearchQuery,
      targetingPlaceholder,
    }
  },
})
