
import {defineComponent, inject} from 'vue';
import {useStore} from 'vuex';
import Navigation from './core/components/Navigation.vue';
import ToastNotification from './core/components/ToastNotification.vue';

export default defineComponent({
  components: {
    Navigation,
    ToastNotification,
  },
  setup() {
    const store = useStore();
    const toastNotificationState = store.state.toastNotification;
    const profileModal = store.state.profiles;


    const Auth0: any = inject('Auth');

    function checkUserLogin() {
      if (!Auth0.isAuthenticated.value) {
        Auth0.loginWithRedirect();
      }
    }

    checkUserLogin();

    return {
      profileModal,
      toastNotificationState,
    };
  },
})
