export type PositiveKeywordResponse = {
  profile_id?: number;
  id: number;
  name: string;
  broad: boolean;
  exact: boolean;
  phrase: boolean;
};

export type NegativeKeywordResponse = Omit<PositiveKeywordResponse, "language">;

export type KeywordResponse = PositiveKeywordResponse | NegativeKeywordResponse;

export type KeywordParams = {
  keyword_group_id: number | null;
  profile_id: string;
  page: string | number;
  search?: string | null;
  language?: string | null;
};

export type NegativeKeywordParams = Omit<KeywordParams, "language" | "keyword_group_id">;

export type KeywordGroupParam = {
  profile_id: string;
  name: string;
  language: string;
};

export type KeywordGroup = {
  id: number;
  name: string;
  language: string;
  status: string;
  keywordCount: number;
};

export type AdGroup = {
  profile_id: string | number;
  language?: string | null;
  search?: string;
};

export type TAddKeywords = {
  profile_id: string | number;
  names: Array<string>;
  language?: string;
  keyword_group_id?: string | number;
};
export type AdGroupItem = {
  id: number;
  language: string;
  name: string;
  status: ProfileStatus;
};

export type Language = {
  code: string;
  name: string;
};

export type ProfileStatus = "enabled" | "disabled";
export type TKeywordType = "negative-keywords-bulk" | "keywords-bulk";

export enum EAdGroup {
  POSITIVE = "keywords-bulk",
  NEGATIVE = "negative-keywords-bulk",
}
