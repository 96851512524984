import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77b95a4a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "edit-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_edit_basic_info = _resolveComponent("edit-basic-info")!
  const _component_synonyms = _resolveComponent("synonyms")!

  return (_openBlock(), _createElementBlock("main", null, [
    _createElementVNode("section", _hoisted_1, [
      _createVNode(_component_edit_basic_info, {
        class: "basic-info",
        profile: _ctx.profile
      }, null, 8, ["profile"]),
      _createVNode(_component_synonyms, {
        class: "synonyms",
        profile: _ctx.profile
      }, null, 8, ["profile"])
    ])
  ]))
}