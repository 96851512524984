import axios from "axios";
import { Profile } from "@/modules/profiles/models/profiles";

import {
  AdGroup,
  AdGroupItem,
  TAddKeywords,
  TKeywordType,
  NegativeKeywordResponse,
  NegativeKeywordParams,
  KeywordParams,
  PositiveKeywordResponse,
  KeywordGroup,
  KeywordGroupParam,
} from "@/modules/keywords/models/keywords";

// TODO - should impelment abstract class as similar in the Launch product
export default class googleSearch {
  public async getAdGroupsByName(params: AdGroup): Promise<AdGroupItem[]> {
    const { data } = await axios.get(`${this.baseUrl}/keyword-groups`, {
      params,
    });
    return data.data;
  }

  public async createKeyword(params: TAddKeywords, type: TKeywordType) {
    const result = await axios.post(`${this.baseUrl}/${type}`, params);
    return result;
  }

  public async updateNegativeKeyword(
    params: Omit<NegativeKeywordResponse, "id">,
    id: string | number
  ) {
    const response = await axios.put(
      `${this.baseUrl}/negative-keywords/${id}`,
      params
    );
    return response.data;
  }

  public async deleteNegativeKeywords(ids: Array<number>) {
    const params = { ids };
    const response = await axios.delete(
      `${this.baseUrl}/negative-keywords-bulk`,
      { params }
    );
    return response.data;
  }
  public async deletePositiveKeywords(ids: Array<number>) {
    const params = { ids };
    const response = await axios.delete(
      `${this.baseUrl}/keywords-bulk`,
      { params }
    );
    return response.data;
  }

  public async getNegativeKeywords(params: NegativeKeywordParams) {
    const { data } = await axios.get(`${this.baseUrl}/negative-keywords`, {
      params,
    });
    return {
      result: data.data,
      links: data.links,
    };
  }

  public async updatePositiveKeywords(
    params: Omit<PositiveKeywordResponse, "id">,
    id: string | number
  ) {
    const response = await axios.put(`${this.baseUrl}/keywords/${id}`, params);
    return response.data;
  }

  public async getPositiveKeywords(params: KeywordParams) {
    const { data } = await axios.get(`${this.baseUrl}/keywords`, {
      params,
    });
    return {
      result: data.data,
      links: data.links,
    };
  }

  public async getKeywordGroups(params: Omit<KeywordParams, "search" | "keyword_group_id">) {
    const { data } = await axios.get(`${this.baseUrl}/keyword-groups`, {
      params,
    });
    return {
      data: data.data.map((item) => this.hydrateKeywordGroups(item)),
      links: data.links,
    };
  }

  public async getKeywordGroupById(id: number | string) {
    const { data } = await axios.get(`${this.baseUrl}/keyword-groups/${id}`);
    return data;
  }

  public async deleteKeywordGroup(id: number) {
    const { data } = await axios.delete(`${this.baseUrl}/keyword-groups/${id}`);
    return data;
  }

  public async moveToNegativeKeywords(params: Record<string, Array<number>>) {
    const { data } = await axios.put(
      `${this.baseUrl}/keywords-bulk/move-to-negative`,
      params
    );
    return data;
  }

  public async updateKeywordGroup(id: number, params: KeywordGroupParam) {
    const { data } = await axios.put(
      `${this.baseUrl}/keyword-groups/${id}`,
      params
    );
    return data;
  }

  public async uploadCSV(data: {
    profileId: number;
    csv: File;
    language: string;
  }): Promise<Profile> {
    const { profileId, csv, language } = data;
    const response = await axios.post(`${this.baseUrl}/keywords-bulk/csv`, {
      profile_id: profileId,
      csv,
      language,
    });
    return response.data;
  }

  protected get baseUrl(): string | undefined {
    return process.env.VUE_APP_WONDERKIND_API_BASE_URL;
  }
  protected hydrateKeywordGroups(json: Record<string, never>): KeywordGroup {
    const profile: KeywordGroup = {
      id: json.id,
      name: json.name,
      status: json.status,
      language: json.language,
      keywordCount: json.keyword_count,
    };
    return profile;
  }
}
