import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-804181ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "facebook-settings" }
const _hoisted_2 = { class: "content-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sidebar = _resolveComponent("sidebar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_sidebar, {
      items: _ctx.navItems,
      onNavigate: _ctx.navigateTo,
      "active-item": _ctx.activeItem
    }, null, 8, ["items", "onNavigate", "active-item"]),
    _createElementVNode("section", _hoisted_2, [
      _createVNode(_component_router_view)
    ])
  ]))
}