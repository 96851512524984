import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4fe9a009"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_navigation = _resolveComponent("navigation")!
  const _component_toast_notification = _resolveComponent("toast-notification")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("section", null, [
      _createVNode(_component_navigation, { class: "navigation" }),
      (_ctx.toastNotificationState.toastParams.isActive)
        ? (_openBlock(), _createBlock(_component_toast_notification, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.profileModal.activeModal.isActive)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.profileModal.activeModal.currentComponent), {
            key: 1,
            class: "modal"
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_router_view)
    ])
  ]))
}