import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-141e18b3"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "targeting-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_facebook_instagram_targeting = _resolveComponent("facebook-instagram-targeting")!
  const _component_edit_targeting = _resolveComponent("edit-targeting")!

  return (_ctx.selectedProfile)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createVNode(_component_facebook_instagram_targeting, {
          profile: _ctx.selectedProfile,
          class: "targeting"
        }, null, 8, ["profile"]),
        _createVNode(_component_edit_targeting, { class: "add-targeting" })
      ]))
    : _createCommentVNode("", true)
}