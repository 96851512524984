
import {computed, defineComponent, PropType} from 'vue';
import {useStore} from 'vuex';
import {Profile} from '../models/profiles';
import {selectedDot, unselectedDot} from '../assets/image.routes';

export default defineComponent({
  props: {
    profile: {default: null, type: null || Object as PropType<Profile>}
  },
  setup() {
    const store = useStore();
    const selectedProfile = store.state.profiles.selectedProfile;
    const profileStatus = computed(() => {
      return store.state.profiles.selectedProfile.status;
    })

    function disable() {
      store.commit('setEnabledDisabled', 'disabled');
      store.commit('setEditingFlag', true);
    }

    function enable() {
      store.commit('setEnabledDisabled', 'enabled');
      store.commit('setEditingFlag', true);
    }

    function deleteProfile() {
      store.commit('setModalParams', {isActive: true, component: 'delete', profile: selectedProfile});
    }
    
    return{
      deleteProfile,
      disable,
      enable,
      profileStatus,
      selectedDot,
      selectedProfile,
      unselectedDot,
    }
  },
})
