import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!

  return (_openBlock(), _createBlock(_component_select_input, {
    class: "language-selector",
    value: _ctx.language,
    options: _ctx.languages,
    "item-label": "name",
    "item-key": "code",
    "no-placeholder": _ctx.noPlaceholder,
    onOnSelect: _cache[0] || (_cache[0] = (data) => _ctx.setData(data))
  }, null, 8, ["value", "options", "no-placeholder"]))
}