
import {defineComponent} from 'vue';
import DefaultButton from './forms/DefaultButton.vue';
import ModalBackdrop from './ModalBackdrop.vue';

export default defineComponent({
  components: {
    DefaultButton,
    ModalBackdrop,
  },
  props: {
    disabled: {default: false, required: false, type: Boolean},
    hasCancel: {default: true, required: false, type: Boolean},
    mainButtonColor: {default: 'var(--color-primary)', type: String}
  },
  setup(props, context) {

    function confirm() {
      context.emit('confirm');
    };

    function close() {
      context.emit('close');
    };

    function secondaryAction() {
      context.emit('secondaryAction');
    };

    return {
      confirm,
      close,
      secondaryAction,
    };
  },
})
