import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/core/assets/icons/icon-dark-close.svg'


const _withScopeId = n => (_pushScopeId("data-v-1a7b1060"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "info" }
const _hoisted_2 = { class: "list-header" }
const _hoisted_3 = { class: "demographics" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.targetingFocus) + " people who match at least one of the following... ", 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.uniquePaths, (path) => {
      return (_openBlock(), _createElementBlock("section", { key: path }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.createPathHeader(path.original)), 1),
          _createElementVNode("img", {
            src: _imports_0,
            alt: "x",
            onClick: ($event: any) => (_ctx.deleteTargetingGroup(path.converted))
          }, null, 8, _hoisted_4)
        ]),
        _createElementVNode("div", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredTargeting(path.converted), (item) => {
            return (_openBlock(), _createElementBlock("li", {
              key: item.id
            }, [
              _createElementVNode("div", null, _toDisplayString(item.name), 1),
              _createElementVNode("img", {
                src: _imports_0,
                alt: "x",
                onClick: ($event: any) => (_ctx.deleteSingleTarget(item.id))
              }, null, 8, _hoisted_5)
            ]))
          }), 128))
        ])
      ]))
    }), 128))
  ]))
}