
import {defineComponent} from 'vue';

export default defineComponent({
  setup() {
    const starCount = 30; 

    function stars() {
      return [...Array(starCount)].map((e) => ({
        y: `${Math.random() * 100}%`,
        x: `${Math.random() * 100}%`,
        size: Math.floor(Math.random() * 2) + 1,
      }));
    }

    return {
      stars,
    };
  },
})
