
import {computed, defineComponent, ref} from 'vue';
import {useRouter} from 'vue-router';
import {useStore} from 'vuex';

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();
    const profileInput = ref('');
    const selectedProfile = computed(() => {
      return store.state.profiles.activeModal.profile;
    })
    const disabled = computed(() => {
      return selectedProfile.value.name !== profileInput.value;
    })

    function close() {
      store.commit('closeModal');
    }

    async function deleteProfile() {
      await store.dispatch('deleteProfile', selectedProfile.value.id);
      close();
      router.push({path: '/'})
    }

    return {
      close,
      deleteProfile,
      disabled,
      profileInput,
      selectedProfile,
    }
  },
})
