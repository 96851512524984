
import {defineComponent, computed} from 'vue';
import {useStore} from 'vuex';
import AutomatedRetargeting from '../components/Automated Retargeting.vue';
import EditBasicInfo from '../components/EditBasicInfo.vue';
import Synonyms from '../components/Synonyms.vue';

export default defineComponent({
  components: {
    AutomatedRetargeting,
    EditBasicInfo,
    Synonyms,
  },
  setup() {
    const store = useStore();
    const profile = computed(() => {
      return store.state.profiles.selectedProfile;
    })

    store.commit('setEditingFlag', false);
    
    return {
      profile,
    }
  },
})
