import { reactive } from "vue";
import googleSearchApi from "@/modules/keywords/api/google-search.api";

import { NegativeKeywordResponse } from "@/modules/keywords/models/keywords";
interface Keywords {
  list: NegativeKeywordResponse[];
  selectedKeywords: NegativeKeywordResponse[];
  searchText: string | null;
  page: 1;
  loading: boolean;
  isLoaded: boolean;
}
export const useNegativeKeyword = (profileId: string) => {
  const keywords: Keywords = reactive({
    list: [],
    selectedKeywords: [],
    searchText: null,
    page: 1,
    loading: false,
    isLoaded: false,
  });

  const fetchNegativeKeywords = async (reset = false) => {
    if (reset) {
      resetKeywords();
    }
    const params = {
      profile_id: profileId,
      page: keywords.page,
      search: keywords.searchText,
    };

    keywords.loading = true;
    const { result, links } =
      await googleSearchApi.prototype.getNegativeKeywords(params);
    keywords.list = keywords.list.concat(result);
    keywords.loading = false;
    if (!links.next) {
      keywords.isLoaded = true;
      return;
    }
    keywords.page += 1;
  };
  const searchKeyword = (text: string) => {
    keywords.searchText = text;
    fetchNegativeKeywords(true);
  };

  const setSelectedKeyword = (items: NegativeKeywordResponse[]) => {
    keywords.selectedKeywords = [...items];
  };

  const resetKeywords = () => {
    keywords.page = 1;
    keywords.list = [];
    keywords.selectedKeywords = [];
  };

  const removeKeyword = async () => {
    if (keywords.selectedKeywords.length > 0) {
      const ids = keywords.selectedKeywords.map(({ id }) => id);
      await googleSearchApi.prototype.deleteNegativeKeywords(ids);
      fetchNegativeKeywords(true);
    }
  };

  const changeMatchType = async (keyword: NegativeKeywordResponse) => {
    const { id, ...rest } = keyword;
    const params = {
      profile_id: Number(profileId),
      ...rest,
    };
    keywords.loading = true;
    await googleSearchApi.prototype.updateNegativeKeyword(params, id);
    keywords.loading = false;
  };

  return {
    keywords,
    fetchNegativeKeywords,
    searchKeyword,
    setSelectedKeyword,
    removeKeyword,
    changeMatchType,
  };
};
