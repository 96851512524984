import {Date} from '../models/coreComponents.model';

// Sorting for Profies
export function stringSort(values, sortBy, sortToggle) {
  let sorted;

  if (sortToggle) {
    sorted = values.sort((a, b) => {
      const aTest = a[sortBy].toLocaleLowerCase();
      const bTest = b[sortBy].toLocaleLowerCase();
      if (aTest < bTest) {
        return -1;
      }
      if (aTest  > bTest) {
        return 1;
      }
      return 0;
    })
  }

  if (!sortToggle) {
    sorted = values.sort((a, b) => {
      const aTest = a[sortBy].toLocaleLowerCase();
      const bTest = b[sortBy].toLocaleLowerCase();
      if (aTest < bTest) {
        return 1;
      }
      if (aTest  > bTest) {
        return -1;
      }
      return 0;
    })
  }
  return sorted;
}

export function numberSort(values, sortBy, sortToggle) {
  let sorted;

  if (sortToggle) {
    sorted = values.sort((a, b) => {
      return a[sortBy] - b[sortBy];
    })
  }
  
  if (!sortToggle) {
    sorted = values.sort((a, b) => {
      return b[sortBy] - a[sortBy];
    })
  }
  return sorted;
}

export function formatDate(info: string): Date {
  const year = info.slice(0 , 4);
  const month = info.slice(5, 7);
  const day = info.slice(8, 10);
  return {
    fullDate: day + '/' + month + '/' + year,
    day,
    month: convertMonth(month),
    year,
  };
}

function convertMonth(month: string) {
  const converted = {
    number: month,
    name: getMonthName(month)!,
  };
  return converted;
}

function getMonthName(date: string) {
  switch (date) {
    case '01': return 'January';
    case '02': return 'February';
    case '03': return 'March';
    case '04': return 'April';
    case '05': return 'May';
    case '06': return 'June';
    case '07': return 'July';
    case '08': return 'August';
    case '09': return 'September';
    case '10': return 'October';
    case '11': return 'November';
    case '12': return 'December';
  }
}

// Time Formatting Logic
function formatTime(info: string) {
  const time = info.slice(11, 13) + info.slice(14, 16) + info.slice(17, 19);
  return parseInt(time);
}

export const sortIcon = require('@/core/assets/icons/icon-sort.svg');