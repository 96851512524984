
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    items: { type: Array, default: () => [] },
    activeItem: { type: String, default: "" },
  },
  emits: {
    navigate: Object,
  },
});
