
import { defineComponent, ref, ComputedRef, computed } from "vue";
import { useStore } from "vuex";
import { Profile } from "../../profiles/models/profiles";
import googleSearchApi from "@/modules/keywords/api/google-search.api";
import LanguageSelector from "../../profiles/components/LanguageSelector.vue";
import LoadingIcon from "../../profiles/components/LoadingIcon.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: { LanguageSelector, LoadingIcon },
  setup() {
    const store = useStore();
    const selectedLanguage = ref("nl");
    const router = useRouter();
    const selectedProfile: ComputedRef<Profile> = computed(() => {
      return store.state.profiles.selectedProfile;
    });
    const isLoading = ref(false);

    function setLanguage(value) {
      selectedLanguage.value = value;
    }

    function resetInputValue(e) {
      e.target.value = "";
    }

    async function onFileChange(file: Event) {
      const fileItem = (file.target as HTMLInputElement)?.files![0];

      if (fileItem.type !== "text/csv") {
        store.dispatch("showToastNotification", {
          isError: true,
          message: "Wrong file type",
        });
        return;
      }

      if (fileItem.type === "text/csv") {
        const reader = new FileReader();
        store.commit("setLoadingState", true);
        reader.onload = async function (fileItem) {
          await uploadCsv(reader.result);
        };
        reader.readAsText(fileItem);
      }
    }

    const uploadCsv = async (csv) => {
      const params = {
        profileId: selectedProfile.value.id,
        language: selectedLanguage.value,
        csv,
      };
      try {
        isLoading.value = true;
        await googleSearchApi.prototype.uploadCSV(params);
        router.push("search-keywords");
      } catch (err: any) {
        store.dispatch("showToastNotification", {
          isError: true,
          message: err.response.data.message,
        });
      } finally {
        isLoading.value = false;
      }
    };

    return {
      selectedLanguage,
      isLoading,
      onFileChange,
      setLanguage,
      resetInputValue,
    };
  },
});
