import { markRaw } from "vue";
import profilesApi from "../api/profiles.api";
import CreateProfileModal from "../modals/CreateProfile.vue";
import CreateSynonym from "../modals/CreateSynonym.vue";
import DeleteProfileModal from "../modals/DeleteProfile.vue";
import DeleteSynonymModal from "../modals/DeleteSynonym.vue";
import DuplicateProfileModal from "../modals/DuplicateProfile.vue";
import SelectLanguageModal from "../modals/SelectLanguage.vue";
import { ModalParams } from "../models/modalParams";
import {
  Profile,
  FunctionGroup,
  ProfileStatus,
  Language,
  Synonym,
  SingleTarget,
  TargetingType,
  TargetingFocus,
} from "../models/profiles";

const profiles = {
  state: {
    activeModal: {
      isActive: false,
      currentComponent: null,
      profile: null,
      synonym: null,
    },
    fieldsEditedFlag: <boolean>false,
    searchTypes: {},
    selectedFunctionGroup: <string>"All",
    selectedProfile: <Profile | null>null,
    selectedTargetingType: <TargetingType>"detailed",
    activityLog: [
      {
        id: 1,
        date: "21-04-2021",
        activity: 'Added synonym "Warehouse Picker"',
        author: "Saskia Riedstra",
      },
      {
        id: 2,
        date: "09-06-2020",
        activity: 'Removed Subprofile "Receiving Associate"',
        author: "Dan van Dijk",
      },
      {
        id: 3,
        date: "17-11-2020",
        activity: "Updated rating to 75",
        author: "Felicia van Jaarsveld",
      },
      {
        id: 4,
        date: "30-01-2021",
        activity: "Updated fee to 5%",
        author: "Esther Ruijter",
      },
    ],
    functionGroups: <FunctionGroup[] | null>null,
    profiles: <Profile[] | null>null,
    subProfiles: null,
    languages: <Language[] | null>null,
    retargetingList: [
      "operator",
      "verzamel-cmpagne",
      "spa-v14",
      "cpa-v16",
      "logistics-associate",
      "shipping-coordinator",
    ],
    synonyms: [
      {
        id: 1,
        name: "Distribution Centre Openings",
        languages: ["All"],
      },
      {
        id: 2,
        name: "Trabajador de Almaceén",
        languages: ["Portugese", "Spanish"],
      },
      {
        id: 3,
        name: "Picker",
        languages: [
          "English",
          "Dutch",
          "German",
          "Spanish",
          "Italian",
          "Swedish",
          "Danish",
        ],
      },
      {
        id: 4,
        name: "Logistiek Mederwerker",
        languages: ["Dutch"],
      },
      {
        id: 5,
        name: "Warehouse Worker",
        languages: ["All"],
      },
      {
        id: 6,
        name: "Kommissionierer",
        languages: ["Dutch", "German"],
      },
    ],
  },

  getters: {
    filteredProfiles(state) {
      if (state.selectedFunctionGroup !== "All") {
        const filtered = state.profiles.filter((profile) => {
          return (
            profile.function_group &&
            profile.function_group.name === state.selectedFunctionGroup
          );
        });
        return filtered;
      }
      return state.profiles;
    },
  },

  mutations: {
    setModalParams(state, params: ModalParams) {
      state.activeModal = {
        isActive: params.isActive,
        currentComponent: selectComponent(params.component),
        profile: params.profile,
        synonym: params.synonym,
      };
    },

    closeModal(state) {
      state.activeModal = {
        isActive: false,
        currentComponent: null,
        profile: null,
      };
    },

    setEditingFlag(state, value: boolean) {
      state.fieldsEditedFlag = value;
    },

    setProfiles(state, profiles: Profile[]) {
      state.profiles = profiles;
    },

    selectProfile(state, profile: Profile) {
      state.selectedProfile = profile;
    },

    createProfile(state, profile: Profile) {
      state.profiles.push(profile);
    },

    deleteProfile(state, id: number) {
      state.profiles = state.profiles.filter((profile: Profile) => {
        return profile.id !== id;
      });
    },

    setFunctionGroups(state, functionGroups: FunctionGroup[]) {
      state.functionGroups = functionGroups;
    },

    setSelectedFunctionGroup(state, name: string) {
      state.selectedFunctionGroup = name;
    },

    setLanguages(state, languages: Language[]) {
      state.languages = languages;
    },

    setEnabledDisabled(state, status: ProfileStatus) {
      state.selectedProfile.status = status;
    },

    setSynonym(state, snyonym: Synonym) {
      state.selectedProfile.synonyms.push(snyonym);
    },

    updateName(state, name: string) {
      state.selectedProfile.name = name;
    },

    updateFunctionGroup(state, name: string) {
      const functionGroup = state.functionGroups.find(
        (item) => item.name === name
      );
      state.selectedProfile.functionGroup = functionGroup;
    },

    updateLanguages(state, languages: Language[]) {
      state.selectedProfile.synonyms.map((synonym: Synonym) => {
        if (synonym.id === state.activeModal.synonym.id) {
          synonym.languages = languages;
        }
      });
    },

    removeSynonymFromProfile(state, synonymId: string) {
      const filteredSynonyms = state.selectedProfile.synonyms.filter((item) => {
        return item.id !== synonymId;
      });
      state.selectedProfile.synonyms = filteredSynonyms;
    },

    setSearchTypes(state, types) {
      state.searchTypes = types;
    },

    setSelectedTargetingType(state, type: TargetingType) {
      state.selectedTargetingType = type;
    },

    addDetailedTargeting(
      state,
      payload: { target: SingleTarget; type: TargetingFocus }
    ) {
      switch (payload.type) {
        case "includes":
          state.selectedProfile.targeting.detailed.includes.push(
            payload.target
          );
          break;
        case "excludes":
          state.selectedProfile.targeting.detailed.excludes.push(
            payload.target
          );
          break;
      }
    },

    addRegulatedTargeting(
      state,
      payload: { target: SingleTarget; type: string }
    ) {
      switch (payload.type) {
        case "includes":
          state.selectedProfile.targeting.regulated.includes.push(
            payload.target
          );
          break;
        case "excludes":
          state.selectedProfile.targeting.regulated.excludes.push(
            payload.target
          );
          break;
      }
    },

    deleteSingleDetailedTarget(
      state,
      payload: { focus: TargetingFocus; targetId: string }
    ) {
      if (payload.focus === "includes") {
        const filtered =
          state.selectedProfile.targeting.detailed.includes.filter((target) => {
            return target.id !== payload.targetId;
          });
        state.selectedProfile.targeting.detailed.includes = filtered;
      }

      if (payload.focus === "excludes") {
        const filtered =
          state.selectedProfile.targeting.detailed.excludes.filter((target) => {
            return target.id !== payload.targetId;
          });
        state.selectedProfile.targeting.detailed.excludes = filtered;
      }
    },

    deleteSingleRegulatedTarget(
      state,
      payload: { focus: TargetingFocus; targetId: string }
    ) {
      if (payload.focus === "includes") {
        const filtered =
          state.selectedProfile.targeting.regulated.includes.filter(
            (target) => {
              return target.id !== payload.targetId;
            }
          );
        state.selectedProfile.targeting.regulated.includes = filtered;
      }

      if (payload.focus === "excludes") {
        const filtered =
          state.selectedProfile.targeting.regulated.excludes.filter(
            (target) => {
              return target.id !== payload.targetId;
            }
          );
        state.selectedProfile.targeting.regulated.excludes = filtered;
      }
    },

    deleteDetailedTargetGroup(
      state,
      payload: { focus: TargetingFocus; path: string }
    ) {
      if (payload.focus === "includes") {
        const filtered =
          state.selectedProfile.targeting.detailed.includes.filter((target) => {
            return convertPath(target.path) !== payload.path;
          });
        state.selectedProfile.targeting.detailed.includes = filtered;
      }

      if (payload.focus === "excludes") {
        const filtered =
          state.selectedProfile.targeting.detailed.excludes.filter((target) => {
            return convertPath(target.path) !== payload.path;
          });
        state.selectedProfile.targeting.detailed.excludes = filtered;
      }
    },

    deleteRegulatedTargetGroup(
      state,
      payload: { focus: TargetingFocus; path: string }
    ) {
      if (payload.focus === "includes") {
        const filtered =
          state.selectedProfile.targeting.regulated.includes.filter(
            (target) => {
              return convertPath(target.path) !== payload.path;
            }
          );
        state.selectedProfile.targeting.regulated.includes = filtered;
      }

      if (payload.focus === "excludes") {
        const filtered =
          state.selectedProfile.targeting.regulated.excludes.filter(
            (target) => {
              return convertPath(target.path) !== payload.path;
            }
          );
        state.selectedProfile.targeting.regulated.excludes = filtered;
      }
    },
  },

  actions: {
    async fetchAllProfiles(context) {
      const profiles = await profilesApi.prototype.findAll();
      context.commit("setProfiles", profiles);
    },

    async fetchSingleProfile(context, id: string) {
      const profile = await profilesApi.prototype.findById(id);
      context.commit("selectProfile", profile);
    },

    async createProfile(context, profileName: string) {
      const profile = await profilesApi.prototype.createProfile(profileName);
      context.commit("createProfile", profile);
      context.commit("selectProfile", profile);
    },

    async deleteProfile(context, profileId: string) {
      await profilesApi.prototype.deleteProfile(profileId);
      context.commit("deleteProfile", profileId);
    },

    async duplicateProfile(
      context,
      params: { name: string; profile: Profile }
    ) {
      const profile = await profilesApi.prototype.duplicateProfile(
        params.profile.id,
        params.name
      );
      context.commit("createProfile", profile);
      context.commit("selectProfile", profile);
    },

    async updateProfile(context, profile) {
      const data = {
        ...profile,
        function_group: profile.functionGroup.id,
      };
      delete data.functionGroup;
      await profilesApi.prototype.updateProfile(data);
      return data;
    },

    async fetchFunctionGroups(context) {
      const functionGroups =
        await profilesApi.prototype.findAllFunctionGroups();
      context.commit("setFunctionGroups", functionGroups);
    },

    // TODO -- will be added in a later addition to Profiles epic - currently synonyms are only managed
    //         on a per profile basis (Curtis)
    // async fetchSynonyms(context) {
    //   const synonyms = await profilesApi.prototype.findSynonymViaSearchParams();
    //   return synonyms;
    // },

    async createSynonym(
      context,
      params: { name: string; languages; profileId: string }
    ) {
      const langCodes = params.languages.map((item) => {
        return item.code;
      });
      try {
        const synonym = await profilesApi.prototype.createSynonym(params.name);
        await profilesApi.prototype.addSynonymToProfile({
          profileId: params.profileId,
          id: synonym.id,
          codes: langCodes,
        });
        context.commit("setSynonym", {
          ...synonym,
          languages: params.languages,
        });
      } catch (error) {
        console.log(error);
      }
    },

    async removeSynonymFromProfile(
      context,
      ids: { profileId: string; synonymId: string }
    ) {
      await profilesApi.prototype.deleteSynonymFromProfile(ids);
      context.commit("removeSynonymFromProfile", ids.synonymId);
    },

    async fetchLanguages(context) {
      const languages = await profilesApi.prototype.findLanguages();
      context.commit("setLanguages", languages);
    },

    async fetchSearchTypes(context) {
      const types = await profilesApi.prototype.findSearchTypes();
      context.commit("setSearchTypes", types);
    },

    async searchTargetingData(
      context,
      info: { value: string; type: TargetingType }
    ) {
      let searchData;
      if (info.type === "detailed") {
        searchData = await profilesApi.prototype.findDetailedTargeting(
          info.value
        );
      }
      if (info.type === "regulated") {
        searchData = await profilesApi.prototype.findRegulatedTargeting(
          info.value
        );
      }
      return searchData;
    },
  },
};

export default profiles;

function selectComponent(name) {
  switch (name) {
    case "":
      return null;
    case "create":
      return markRaw(CreateProfileModal);
    case "create-synonym":
      return markRaw(CreateSynonym);
    case "delete":
      return markRaw(DeleteProfileModal);
    case "delete-synonym":
      return markRaw(DeleteSynonymModal);
    case "duplicate":
      return markRaw(DuplicateProfileModal);
    case "select-language":
      return markRaw(SelectLanguageModal);
  }
}

// Converts an array of paths to a concatinated lowercase string for easy comparing
export function convertPath(path) {
  return path
    .slice(0, path.length - 1)
    .map((pathString) => {
      return pathString.replace(/\s/g, "").toLowerCase();
    })
    .join("");
}
