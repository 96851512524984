
import {defineComponent, ref, computed} from 'vue';
import {useStore} from 'vuex';
import FunctionGroupSelector from '../components/FunctionGroupSelector.vue';
import ProfileDropDown from '../components/ProfileDropDown.vue';
import Search from '../../../core/components/Search.vue';
import {sortIcon, stringSort, numberSort} from '../../../core/hooks/sortingHooks';

export default defineComponent({
  components: {
    FunctionGroupSelector,
    ProfileDropDown,
    Search,
  },
  setup() {
    const store = useStore();
    let sortToggle = ref(true);
    const subProfiles = computed(() => store.state.profiles.subProfiles)

    function sort(sortBy) {
      sortToggle.value = !sortToggle.value;
      sortBy === 'name' || sortBy === 'parentProfile' ? 
      stringSort(subProfiles.value, sortBy, sortToggle.value) :
      numberSort(subProfiles.value, sortBy, sortToggle.value); 
    }
    
    return {
      sort,
      sortIcon,
      subProfiles,
    }
  },
})
