
import {defineComponent, PropType} from 'vue';
import {useStore} from 'vuex';
import LanguageTag from '../components/LanguageTag.vue';
import {Profile, Synonym} from '../models/profiles';

export default defineComponent({
  components: {
    LanguageTag,
  },
  props: {
    profile: {default: null, type: null || Object as PropType<Profile>}
  },
  setup(props) {
    const store = useStore();
    const synonyms = store.state.profiles.synonyms;

    function addSynonym() {
      store.commit('setModalParams', 
        {
          isActive: true, 
          component: 'create-synonym', 
          profile: store.state.profiles.selectedProfile
        }
      );
    }

    function sliceLanguages(languages) {
      const firstThreeLangs = languages.slice(0, 3);
      return firstThreeLangs;
    }

    function openDeleteModal(synonym: Synonym) {
      store.commit('setModalParams', {isActive: true, component: 'delete-synonym', synonym});
    }

    function openLanguageModal(synonym: Synonym) {
      store.commit('setModalParams', {isActive: true, component: 'select-language', synonym})
    }

    return {
      addSynonym,
      openDeleteModal,
      openLanguageModal,
      sliceLanguages,
      synonyms,
    }
  },
  })
