
import {defineComponent, onMounted, ref} from 'vue';

export default defineComponent({
  props: {
    horizontalOffset: {default: 0, type: Number},
    verticalOffset: {default: 0, type: Number},
  },
  setup(props) {
    let showToolTip = ref<boolean>(false);
    const toolTip = ref<HTMLElement | null>(null);
    
    onMounted(() => {
      if (toolTip.value!) {
        toolTip.value.style.bottom = props.verticalOffset + 'px';
        const horizontalOffset = props.horizontalOffset - (toolTip.value.clientWidth / 2);
        toolTip.value.style.left = horizontalOffset + 'px';
      }
    })

    return {
      showToolTip,
      toolTip,
    };
  },
})
