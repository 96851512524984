
import { defineComponent, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import Sidebar from "@/modules/keywords/components/Sidebar.vue";

export default defineComponent({
  components: { Sidebar },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const navItems: Array<Record<string, string>> = [
      {
        content: "Search Keywords",
        name: "search-keywords",
      },
      {
        content: "Negative Keywords",
        name: "negative-keywords",
      },
      {
        content: "Add Keywords",
        name: "add-keywords",
      },
      {
        content: "Upload CSV",
        name: "upload-csv",
      },
    ];

    let activeItem = ref("");

    function navigateTo(section) {
      activeItem.value = section.name;
      router.push({ name: activeItem.value });
    }

    onMounted(() => {
      activeItem.value = route.name as string;
    });

    return {
      navItems,
      activeItem,
      navigateTo,
    };
  },
});
