import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Profiles from "./modules/profiles";
import Keywords from "./modules/keywords";
import Auth from "./auth/auth0";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/profiles",
    beforeEnter: Auth.routeGuard,
  },
  ...Profiles,
  ...Keywords,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(Auth.routeGuard);

export default router;
