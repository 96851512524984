import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, vModelDynamic as _vModelDynamic, withKeys as _withKeys, withDirectives as _withDirectives, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ae82ca6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = {
  key: 0,
  class: "char-count"
}
const _hoisted_3 = ["disabled", "type", "placeholder", "id", "maxlength"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_alert = _resolveComponent("icon-alert")!
  const _component_form_tool_tip = _resolveComponent("form-tool-tip")!

  return (_openBlock(), _createElementBlock("main", {
    class: _normalizeClass(["input", {"error" : _ctx.hasError, "disabled" : _ctx.disabled}])
  }, [
    _createVNode(_component_form_tool_tip, {
      "has-error": _ctx.hasError,
      "horizontal-offset": -9,
      "vertical-offset": 30,
      "hide-tool-tip": !_ctx.hasWarning || !_ctx.hasCharacters,
      "corner-location": _ctx.location,
      "location-style": _ctx.locationStyle
    }, {
      hoverable: _withCtx(() => [
        _createElementVNode("label", {
          for: _ctx.id,
          style: _normalizeStyle(_ctx.disabledStyle)
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true),
          (_ctx.hasCharCount)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.value.length), 1))
            : _createCommentVNode("", true),
          (_ctx.hasCharacters && _ctx.hasWarning)
            ? (_openBlock(), _createBlock(_component_icon_alert, {
                key: 1,
                class: "alert",
                color: _ctx.hasError ? "var(--color-warning)" : "var(--color-primary)"
              }, null, 8, ["color"]))
            : _createCommentVNode("", true)
        ], 12, _hoisted_1)
      ]),
      "tool-tip": _withCtx(() => [
        _createElementVNode("div", null, _toDisplayString(_ctx.errorOptions.warningMessage), 1)
      ]),
      _: 3
    }, 8, ["has-error", "hide-tool-tip", "corner-location", "location-style"]),
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
      disabled: _ctx.disabled,
      type: _ctx.type,
      style: _normalizeStyle({backgroundColor: _ctx.fill}),
      placeholder: _ctx.placeholder,
      id: _ctx.id,
      maxlength: _ctx.maxlength,
      onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.enter && _ctx.enter(...args)), ["enter"])),
      onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.blur && _ctx.blur(...args)))
    }, null, 44, _hoisted_3), [
      [
        _vModelDynamic,
        _ctx.inputValue,
        void 0,
        { trim: true }
      ]
    ])
  ], 2))
}