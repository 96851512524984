
import { defineComponent } from "vue";

import ProfileNavBar from "@/modules/profiles/components/ProfileNavBar.vue";

export default defineComponent({
  components: {
    ProfileNavBar,
  },
  setup() {
    return {};
  },
});
