import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-091dc384"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "autocomplete" }
const _hoisted_2 = {
  key: 0,
  class: "list"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_text_input, {
      id: "gruops",
      class: "search-group-input",
      placeholder: "Search for ad groups",
      type: "text",
      disabled: _ctx.disabled,
      value: _ctx.searchTerm,
      onSetData: _ctx.searchItem
    }, null, 8, ["disabled", "value", "onSetData"]),
    (_ctx.isOpen)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "list-item",
              key: option.id,
              onClick: _withModifiers(($event: any) => (_ctx.setSelectedItem(option)), ["prevent"])
            }, _toDisplayString(option.name), 9, _hoisted_3))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ])), [
    [_directive_click_outside, _ctx.onClickOutside]
  ])
}