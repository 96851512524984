import axios from "axios";
import { formatDate } from "../../../core/hooks/sortingHooks";
import {
  Profile,
  FunctionGroup,
  Synonym,
  Language,
  ProfileStatus,
  SingleTarget,
  FullTargeting,
} from "@/modules/profiles/models/profiles";

// TODO - should impelment abstract class as similar in the Launch product
export default class profilesApi {
  public async findAll(): Promise<Profile[]> {
    const response = await axios.get(this.apiRoute);
    return response.data;
  }

  public async findById(id: string): Promise<Profile> {
    const response = await axios.get(this.apiRoute + "/" + id);
    return this.hydrate(response.data as ProfileResponseJson);
  }

  public async createProfile(name: string) {
    const response = await axios.post(this.apiRoute, { name });
    return this.hydrate(response.data as ProfileResponseJson);
  }

  public async deleteProfile(id: string) {
    const response = await axios.delete(this.apiRoute + "/" + id);
    return response;
  }

  public async duplicateProfile(id: number, name: string): Promise<Profile> {
    const response = await axios.post(this.apiRoute + `/duplicate/${id}`, {
      name,
    });
    return this.hydrate(response.data as ProfileResponseJson);
  }

  public async updateProfile(profile): Promise<Profile> {
    const response = await axios.put(this.apiRoute + `/${profile.id}`, profile);
    return response.data;
  }

  public async findAllFunctionGroups(): Promise<FunctionGroup[]> {
    const response = await axios.get(this.apiRoute + "/function-groups");
    return response.data;
  }

  // TODO -- currently unused and will be added in a later addition to this epic when synonyms are
  //         managed separately not just by pofiles (Curtis)
  // TODO -- Set search params to a variable insted of hardcoded (Curtis)
  // public async findSynonymViaSearchParams(): Promise<Synonym[]> {
  //   const response = await axios.get(this.apiRoute + '/synonyms?q=dev');
  //   return response.data;
  // }

  public async createSynonym(name: string): Promise<Synonym> {
    const response = await axios.post(this.apiRoute + "/synonyms", { name });
    return response.data;
  }

  public async addSynonymToProfile(params: {
    profileId: string;
    id: number;
    codes: string[];
  }) {
    const response = await axios.post(
      this.apiRoute + `/${params.profileId}/synonyms`,
      { id: params.id, codes: params.codes }
    );
    return response.data;
  }

  public async deleteSynonymFromProfile(params) {
    const response = await axios.delete(
      this.apiRoute + `/${params.profileId}/synonyms/${params.synonymId}`
    );
    return response.data;
  }

  // public async deleteGlobalSynonym() {
  // TODO -- this will be functionaltiy for a later add on to this epic when synonyms are managed separately
  //         in addition to with profiles -- (Curtis)
  // }

  public async findLanguages(): Promise<Language[]> {
    const response = await axios.get(this.apiRoute + "/synonyms/languages");
    return response.data;
  }

  public async findSearchTypes() {
    const response = await axios.get(
      this.apiRoute + "/detailed-targeting/search-types"
    );
    return response.data;
  }

  public async findDetailedTargeting(query: string) {
    const response = await axios.get(
      this.apiRoute + `/detailed-targeting?q=${query}`
    );
    return response.data;
  }

  public async findRegulatedTargeting(query: string) {
    const response = await axios.get(
      this.apiRoute + `/detailed-targeting?q=${query}&regulated=1`
    );
    return response.data;
  }

  protected get apiName(): string {
    return "/profiles";
  }

  protected get apiRoute(): string {
    return this.baseUrl + this.apiName;
  }

  protected get baseUrl(): string | undefined {
    return process.env.VUE_APP_WONDERKIND_API_BASE_URL;
  }

  protected hydrate(json: ProfileResponseJson): Profile {
    const profile: Profile = {
      id: json.id,
      name: json.name,
      functionGroup: json.function_group as FunctionGroup,
      subProfiles: json.subprofiles,
      rating: json.rating,
      status: json.status as ProfileStatus,
      used: 0,
      ctr: json.ctr,
      cpc: json.cpc,
      cpm: json.cpm,
      synonyms: json.synonyms as Synonym[],
      targeting: json.targeting as FullTargeting,
      createdAt: formatDate(json.created_at),
      updatedAt: formatDate(json.updated_at),
    };
    return profile;
  }
}

export interface ProfileResponseJson {
  id: number;
  name: string;
  function_group: FunctionGroup;
  subprofiles: number;
  rating: number;
  status: ProfileStatus;
  used: number;
  ctr: number;
  cpc: number;
  cpm: number;
  synonyms: Synonym[];
  targeting: {
    detailed: {
      includes: SingleTarget[];
      excludes: SingleTarget[];
    };
    regulated: {
      includes: SingleTarget[];
      excludes: SingleTarget[];
    };
  };
  created_at: string;
  updated_at: string;
}
