
import {defineComponent, ref, PropType, computed} from 'vue';
import {useStore} from 'vuex';
import Targeting from './Targeting.vue';
import {TargetingType, Profile} from '../models/profiles';

export default defineComponent({
  components: {
    Targeting,
  },
  props: {
    profile: {default: null, type: null || Object as PropType<Profile>},
  },
  setup(props) {
    const store = useStore();
    const activeTargetingType = computed(() => {
      return store.state.profiles.selectedTargetingType;
    })

    function selectTargetingType(type: TargetingType) {
      store.commit('setSelectedTargetingType', type);
    }
    
    return {
      activeTargetingType,
      selectTargetingType,
    }
  },
})
