import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_keyword = _resolveComponent("search-keyword", true)!

  return (_openBlock(), _createBlock(_component_search_keyword, {
    class: "search-keyword",
    keywords: _ctx.keywords.list,
    "selected-keywords": _ctx.keywords.selectedKeywords,
    "selected-language": _ctx.keywords.selectedLanguage,
    "is-loaded": _ctx.keywords.isLoaded,
    loading: _ctx.keywords.loading,
    groups: _ctx.groups.list,
    "group-keyword-loading": _ctx.groups.loading,
    "group-keyword-loaded": _ctx.groups.isLoaded,
    "selected-group-row": _ctx.keywords.keywordGroupId,
    onRemoveGroupItem: _ctx.onRemoveGroupItem,
    onLoadMoreGroups: _ctx.fetchKeywordGroups,
    onUpdateGroupName: _ctx.updateGroupName,
    onLoadKeywords: _ctx.fetchKeywords,
    onSearch: _ctx.searchKeyword,
    onRemoveKeyword: _ctx.removeKeywordItems,
    onSetSelectedKeyword: _ctx.setSelectedKeyword,
    onSetSelectedLanguage: _ctx.updateSelectedLanguage,
    onChangeMatchType: _ctx.changeMatchType,
    onMoveToNegative: _ctx.moveToNegativeKeywords,
    onSelectedGroupItem: _ctx.selectedGroupItem
  }, null, 8, ["keywords", "selected-keywords", "selected-language", "is-loaded", "loading", "groups", "group-keyword-loading", "group-keyword-loaded", "selected-group-row", "onRemoveGroupItem", "onLoadMoreGroups", "onUpdateGroupName", "onLoadKeywords", "onSearch", "onRemoveKeyword", "onSetSelectedKeyword", "onSetSelectedLanguage", "onChangeMatchType", "onMoveToNegative", "onSelectedGroupItem"]))
}