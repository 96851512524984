import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/core/assets/icons/icon-white-close.svg'


const _withScopeId = n => (_pushScopeId("data-v-61d558ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "notification-wrapper" }
const _hoisted_2 = {
  key: 0,
  src: _imports_0,
  alt: "close",
  class: "close-icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeNotification && _ctx.closeNotification(...args))),
      class: _normalizeClass(["notification", {
        error: _ctx.notificationState.isError,
        success: _ctx.notificationState.isSuccess,  
      }])
    }, [
      _createTextVNode(_toDisplayString(_ctx.notificationState.message) + " ", 1),
      (_ctx.notificationState.isError)
        ? (_openBlock(), _createElementBlock("img", _hoisted_2))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}