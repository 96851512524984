
import {defineComponent, ref, computed} from 'vue';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();
    const profileInput = ref('');
    const selectedProfile = computed(() => {
      return store.state.profiles.activeModal.profile;
    })
    const duplicateDisabled = computed(() => {
      return profileInput.value === "" 
        || selectedProfile.value.name.toLowerCase() === profileInput.value.toLowerCase();
    })

    function close() {
      store.commit('closeModal');
    }

    async function duplicateProfile() {
      await store.dispatch('duplicateProfile', {name: profileInput.value, profile: selectedProfile.value});
      const profileId = store.state.profiles.selectedProfile.id;
      router.push({name: 'edit-profile', params: {profileId}});
      close();
    }
    
    return {
      close,
      duplicateDisabled,
      duplicateProfile,
      profileInput,
      selectedProfile,
    }
  },
})
