
import {computed, defineComponent, PropType} from 'vue';
import {useStore} from 'vuex';
import TargetingData from './TargetingData.vue';
import {Profile, TargetingType} from '../models/profiles';

export default defineComponent({
  components: {
    TargetingData,
  },
  props: {
    profile: {default: null, type: null || Object as PropType<Profile>},
    targetingType: {default: 'detailed', required: true, type: String as PropType<TargetingType>},
  },
  setup(props) {
    const store = useStore();
    const detailedTargeting = computed(() => {
      return store.state.profiles.selectedProfile.targeting.detailed;
    })
    const regulatedTargeting = computed(() => {
      return store.state.profiles.selectedProfile.targeting.regulated;
    })

    return {
      detailedTargeting,
      regulatedTargeting,
    }
  },
})
