
// Compoenents
import { defineComponent, ref, computed, PropType, watch } from "vue";
import SelectInput from "@/core/components/forms/SelectInput.vue";
import EditableContentInput from "@/core/components/EditableContentInput.vue";
import DefaultButton from "@/core/components/forms/DefaultButton.vue";
import Autocomplete from "@/core/components/forms/Autocomplete.vue";
import LanguageSelector from "@/modules/profiles/components/LanguageSelector.vue";

// Models - Service
import googleSearchApi from "@/modules/keywords/api/google-search.api";
import { AdGroupItem, EAdGroup } from "@/modules/keywords/models/keywords";
import { languages } from "@/core/store/languageList";
import debounce from "lodash.debounce";

const keywordTypes = [
  { id: EAdGroup.NEGATIVE, name: "Add New Negative Keywords" },
  { id: EAdGroup.POSITIVE, name: "Add New Keywords" },
];
export interface IselectedData {
  keywordCategory: string;
  language: string;
  group: Record<string, string>;
  keywords: string;
}

export default defineComponent({
  components: {
    SelectInput,
    EditableContentInput,
    DefaultButton,
    Autocomplete,
    LanguageSelector,
  },
  props: {
    selectedItems: {
      default: () => ({}),
      type: Object as PropType<IselectedData>,
    },
    profileId: { default: null, type: String || Number || undefined },
  },
  emits: {
    submit: Object,
  },
  setup(props, { emit }) {
    let localSelectItems = ref<IselectedData>({
      keywordCategory: EAdGroup.POSITIVE,
      language: "",
      group: {},
      keywords: "",
    });
    let keywordCategories = ref(keywordTypes);
    let searchGroups = ref<AdGroupItem[]>([]);

    let isDisabledLanguageAndGroups = computed(() => {
      return localSelectItems.value.keywordCategory === EAdGroup.NEGATIVE;
    });

    const setData = (item) => {
      localSelectItems.value = {
        ...localSelectItems.value,
        ...item,
      };
    };

    const searchAdGroup = debounce(async (text: string) => {
      const params = {
        profile_id: props.profileId,
        search: text.trim(),
      };
      const data = await googleSearchApi.prototype.getAdGroupsByName(params);
      searchGroups.value = data;
    }, 500);

    const submit = () => {
      emit("submit", localSelectItems.value);
    };

    watch(
      () => props.selectedItems,
      (newVal) => {
        localSelectItems.value = newVal;
      },
      { immediate: true, deep: true }
    );

    return {
      localSelectItems,
      languages,
      searchGroups,
      keywordCategories,
      isDisabledLanguageAndGroups,
      submit,
      setData,
      searchAdGroup,
    };
  },
});
