
import { defineComponent, ref, onMounted, onUnmounted, watch, computed } from "vue";
import LoadingSpinner from "@/core/components/LoadingSpinner.vue";
import debounce from "lodash.debounce";

export default defineComponent({
  components: { LoadingSpinner },
  props: {
    header: { type: Array, default: () => [] },
    items: { type: Array, default: () => [] },
    footer: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
    isLoaded: { type: Boolean, default: false },
  },
  emits: {
    loadMore: null,
  },
  setup(props, { emit }) {
    const table = ref<HTMLTableElement | null>(null);
    const localLoading = ref(false);

    const hasResult = computed(() => !localLoading.value && props.isLoaded && props.items?.length === 0);
    const loadMore = debounce((event: Event) => {
      if (!props.isLoaded && !localLoading.value && !!table.value) {
        const element = event.target as HTMLElement;
        if (element.scrollTop + element.clientHeight >= element.scrollHeight) {
          localLoading.value = true;
          emit("loadMore");
        }
      }
    }, 500);

    onMounted(() => {
      if (table.value) {
        table.value.addEventListener("scroll", loadMore);
      }
    });

    onUnmounted(() => {
      if (table.value) {
        table.value.removeEventListener("scroll", loadMore);
      }
    });

    watch(
      () => props.loading,
      (val) => {
        localLoading.value = val;
      },
    );
    return {
      hasResult,
      localLoading,
      table,
    };
  },
});
