import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e6c5337a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile-nav-bar" }
const _hoisted_2 = {
  key: 0,
  class: "profile"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.selectedProfile)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.selectedProfile.name), 1))
      : _createCommentVNode("", true),
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigationData, (navItem, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: _normalizeClass({
          active: _ctx.activeNavItem === navItem.routeName,
          'temporary-disabled': !_ctx.isNavItemActive(navItem.routeName),
        }),
          onClick: ($event: any) => (_ctx.navigateTo(navItem.routeName))
        }, _toDisplayString(navItem.content), 11, _hoisted_3))
      }), 128))
    ])
  ]))
}