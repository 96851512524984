
import { defineComponent, ref, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  components: {},
  setup() {
    const route = useRoute();
    const store = useStore();
    const { parentName } = route.meta;
    let activeNavItem = ref(parentName);
    const selectedProfile = computed(() => {
      return store.state.profiles.selectedProfile;
    });
    const activeNavItems = [
      "google-search",
      "edit-profile",
      "facebook-settings",
    ];
    const router = useRouter();
    const navigationData = [
      {
        routeName: "google-search",
        content: "Google Search",
      },
      {
        routeName: "google-display",
        content: "Google Display(soon)",
      },
      {
        routeName: "facebook-settings",
        content: "Facebook",
      },
      {
        routeName: "edit-profile",
        content: "Settings",
      },
    ];

    function isNavItemActive(navItem) {
      return activeNavItems.includes(navItem);
    }

    function navigateTo(page: string) {
      activeNavItem.value = page;
      router.push({ name: page });
    }

    onMounted(async () => {
      if (!selectedProfile.value) {
        const id = router.currentRoute.value.params.profileId;
        await store.dispatch("fetchSingleProfile", id);
      }
    });

    return {
      selectedProfile,
      activeNavItem,
      navigationData,
      navigateTo,
      isNavItemActive,
    };
  },
});
