import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34dc81c9"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 0,
  class: "option empty-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("main", {
    class: "input-selection",
    ref: "inputSelection",
    style: _normalizeStyle({maxHeight: `${_ctx.maxHeight}px`, backgroundColor: _ctx.fill})
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: option.id,
        class: _normalizeClass([{"focus" : index === _ctx.focusedOptionIndex, invald: option.invalid}, "option"]),
        style: _normalizeStyle({height: `${_ctx.optionHeight}px`}),
        onClick: ($event: any) => (_ctx.select(option))
      }, [
        (option.title)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "title",
              innerHTML: _ctx.highlight(option.title, _ctx.titleHighlight)
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true),
        (option.tag)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "tag",
              style: _normalizeStyle({backgroundColor: option.tag.color})
            }, _toDisplayString(option.tag.title), 5))
          : _createCommentVNode("", true)
      ], 14, _hoisted_1))
    }), 128)),
    (!_ctx.options.length && !!_ctx.titleHighlight && !_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "no-results-message", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ], 4))
}