
import {defineComponent} from 'vue';
import OptionsDropDown from './OptionsDropDown.vue';

export default defineComponent({
  components: {
    OptionsDropDown,
  },

})
