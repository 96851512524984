
import {computed, defineComponent, PropType} from 'vue';
import {useStore} from 'vuex';
import {SingleTarget, TargetingFocus} from '../models/profiles';
import {convertPath} from '../store/index';

export default defineComponent({
  props: {
    targeting: {required: true, type: Array as PropType<SingleTarget[]>},
    targetingFocus: {default: 'includes', type: String as PropType<TargetingFocus>}
  },
  setup(props) {
    const store = useStore();
    const type = store.state.profiles.selectedTargetingType;
    
    // Shows how many indiviudal uqinue paths there are in the targeting subset
    const uniquePaths = computed(() => {
      let updatedArray: any = [];
      props.targeting.map((target) => {
        const path = convertPath(target.path);
        if (checkIncludes(path, updatedArray)){
          const newPath = {original: target.path, converted: path}
          updatedArray.push(newPath);
        }
      })
      return updatedArray;
    })

    // Destructures objects to check if converted array already includes a new path
    function checkIncludes(path, array) {
      const mapped = array.map((item) => {
        return item.converted;
      })
      if (mapped.includes(path)) {
        return;
      }
      return true;
    }

    // Filters targeting based on the path for the HTMl template code
    function filteredTargeting(path) {
      const filtered = props.targeting.filter((target) => {
        return convertPath(target.path) === path;
      });
      return filtered;
    }

    // Creates correct string format for the group header in the HTML
    function createPathHeader(path: any) {
      let paths: any = [];
      path.forEach((items: any, index) => {
        if (index !== path.length - 1) {
          paths.push(items);
          paths.push(' > ');
        }
        if (index === path.length - 1)  {
        paths.push(items);
        }
      })
      return paths.slice(0, paths.length - 2).join('');
    }

    // Calls store function to delete all targets mathing a identical path
    function deleteTargetingGroup(path: string) {
      if (type === 'detailed') {
        store.commit('deleteDetailedTargetGroup', {focus: props.targetingFocus, path});  
      }
      if (type === 'regulated') {
        store.commit('deleteRegulatedTargetGroup', {focus: props.targetingFocus, path})
      }
      store.commit('setEditingFlag', true);
    }

    // Calls store function to delete a single piece of targeting from 
    function deleteSingleTarget(targetId: string) {
      if (type === 'detailed') {
        store.commit('deleteSingleDetailedTarget', {focus: props.targetingFocus, targetId});
      }
      if (type === 'regulated') {
        store.commit('deleteSingleRegulatedTarget', {focus: props.targetingFocus, targetId});
      }
      store.commit('setEditingFlag', true);
    }

    return {
      createPathHeader,
      deleteSingleTarget,
      deleteTargetingGroup,
      filteredTargeting,
      uniquePaths,
    }
  },
})
