import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a6e5f492"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "alert-icon",
  viewBox: "0 0 24 24",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M13 14H11V10H13V14ZM13 18H11V16H13V18ZM1.86902 19.499C1.48306 20.1656 1.96411 21 2.73445 21H21.2656C22.0359 21 22.5169 20.1656 22.131 19.499L12.8654 3.49482C12.4803 2.82954 11.5197 2.82954 11.1346 3.49483L1.86902 19.499Z",
      fill: _ctx.color
    }, null, 8, _hoisted_2)
  ]))
}