
import vClickOutside from "click-outside-vue3";

import TextInput from "@/core/components/forms/TextInput.vue";
import {
  onMounted,
  defineComponent,
  ref,
  computed,
  PropType,
  watch,
} from "vue";
import { AutoComplete } from "@/core/models/coreComponents.model";

export default defineComponent({
  components: { TextInput },
  directives: { clickOutside: vClickOutside.directive },
  props: {
    options: { type: Array as PropType<AutoComplete[]>, default: () => [] },
    value: { type: String, default: '' },
    disabled: {type: Boolean, default: false}
  },
  emits: {
    search: Object,
    select: Object,
  },
  setup(props, { emit }) {
    let searchTerm = ref("");
    let isOpen = ref(false);

    const setSelectedItem = (item) => {
      isOpen.value = false;
      emit("select", item);
    };
    const searchItem = (item) => {
      isOpen.value = true;
      emit("search", item);
      emit("select", null);
    };

    const onClickOutside = () => {
      if (isOpen.value) {
        isOpen.value = false;
      }
    };

    watch(
      () => props.value,
      (newval) => {
        searchTerm.value = newval;
      }
    );

    return {
      isOpen,
      searchTerm,
      setSelectedItem,
      searchItem,
      onClickOutside,
    };
  },
});
