
import {computed, defineComponent, ref} from 'vue';
import {useStore} from 'vuex';

export default defineComponent({
  setup() {
    const store = useStore();
    const closeDropDown = ref(false);
    const functionGroups = computed(() => {
      return store.state.profiles.functionGroups;
    })
    const selectedFunctionGroup = computed(() => {
      return store.state.profiles.selectedFunctionGroup;
    })

    function selectFunctionGroup(functionGroup) {
      store.commit('setSelectedFunctionGroup', functionGroup);
      closeDropDown.value = !closeDropDown.value;
    }

    return {
      closeDropDown,
      functionGroups,
      selectFunctionGroup,
      selectedFunctionGroup,
    }
  },
})
