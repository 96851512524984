
import { defineComponent, PropType } from "vue";
import KeywordForm from "@/modules/keywords/components/KeywordForm.vue";
import DefaultButton from "@/core/components/forms/DefaultButton.vue";

import { PositiveKeywordResponse } from "@/modules/keywords/models/keywords";
import KeywordGroupTable from "@/modules/keywords/components/KeywordGroupTable.vue";

export default defineComponent({
  components: { KeywordForm, KeywordGroupTable, DefaultButton },
  props: {
    keywords: {
      type: Array as PropType<PositiveKeywordResponse[]>,
      default: () => [],
    },
    selectedLanguage: { type: String, default: "" },
    selectedKeywords: {
      type: Array as PropType<PositiveKeywordResponse[]>,
      default: () => [],
    },
    selectedGroupRow: { type: Number, default: null },
    groups: { type: Array, default: () => [] },
    isLoaded: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    groupKeywordLoading: { type: Boolean, default: false },
    groupKeywordLoaded: { type: Boolean, default: false },
  },
  emits: {
    search: null,
    loadMoreGroups: null,
    setSelectedLanguage: String,
    changeMatchType: Object,
    loadKeywords: null,
    removeKeyword: null,
    moveToNegative: null,
    updateGroupName: Object,
    setSelectedKeyword: String,
    selectedGroupItem: Object,
  },
  setup() {
    const groupHeader = [
      { name: "Group Name", width: "auto" },
      { name: "KW", width: "75px" },
    ];
    return {
      groupHeader,
    };
  },
});
