import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77b27810"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "body" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "no-targeting" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "no-targeting" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_targeting_data = _resolveComponent("targeting-data")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.targetingType === "detailed")
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (!_ctx.detailedTargeting.excludes.length && !_ctx.detailedTargeting.includes.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("h3", _hoisted_4, " No " + _toDisplayString(_ctx.targetingType) + " targeting has been added yet... ", 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.detailedTargeting.excludes.length)
            ? (_openBlock(), _createBlock(_component_targeting_data, {
                key: 1,
                targeting: _ctx.detailedTargeting.excludes,
                targetingFocus: "excludes"
              }, null, 8, ["targeting"]))
            : _createCommentVNode("", true),
          (_ctx.detailedTargeting.includes.length)
            ? (_openBlock(), _createBlock(_component_targeting_data, {
                key: 2,
                targeting: _ctx.detailedTargeting.includes,
                targetingFocus: "includes"
              }, null, 8, ["targeting"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.targetingType === "regulated")
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (!_ctx.regulatedTargeting.excludes.length && !_ctx.regulatedTargeting.includes.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("h3", _hoisted_7, " No " + _toDisplayString(_ctx.targetingType) + " targeting has been added yet... ", 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.regulatedTargeting.excludes.length)
            ? (_openBlock(), _createBlock(_component_targeting_data, {
                key: 1,
                targeting: _ctx.regulatedTargeting.excludes,
                targetingFocus: "excludes"
              }, null, 8, ["targeting"]))
            : _createCommentVNode("", true),
          (_ctx.regulatedTargeting.includes.length)
            ? (_openBlock(), _createBlock(_component_targeting_data, {
                key: 2,
                targeting: _ctx.regulatedTargeting.includes,
                targetingFocus: "includes"
              }, null, 8, ["targeting"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}