
import {defineComponent} from 'vue';
import {useStore} from 'vuex';

export default defineComponent({
  setup() {
    const store = useStore();

    function openProfileModal() {
      store.commit('setModalParams', {isActive: true, component: 'create'})
    }
    
    return {
      openProfileModal,
    }
  },
})
